import _ from "lodash"
import React, { useState } from "react"
import LongPress from "react-long"
import { useApp } from "../../../store/app/selectors"
import { useItem } from "../../../store/items/selectors"
import { AddParentModal } from "../add_parent"
import IconButton from "@material-ui/core/IconButton"
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown"
import DropDownMenu from "../../elements/drop_down_menu"

// ========================================= //
// Main Component
// ========================================= //

const OptionsMenu = ({
  id,
  parentID,
  childID,
  include = [],
  useContextMenu,
  children,
  className,
}) => {
  // ========================================= //
  // State
  // ========================================= //

  // This is a hack since MaterialUi context menu isn't working on mobile
  const [isOpen, setIsOpen] = useState(false)
  const [showMove, setShowMove] = useState(false)
  const [, { setSetting, setMenuItem }] = useApp(false)
  const [parentIDs, actions] = useItem([id, "parentIDs"])
  const [childParentIDs] = useItem([childID, "parentIDs"])
  const [childCount] = useItem([id, "itemIDs"], _.size)
  const [deletedAt] = useItem([id, "deletedAt"])
  const options = _.filter(
    [
      { id: "edit", name: "Quick edit" },
      { id: "move", name: "Move" },
      { id: "add", name: "Add parent" },
      { id: "remove", name: "Remove" },
      { id: "removeParent", name: "Remove as parent" },
      { id: "delete", name: "Delete" },
    ],
    (o) => (_.size(include) ? _.includes(include, o.id) : true)
  )

  // only allow removal if more than one parent
  if (childID || _.size(parentIDs) < 2)
    _.pullAllBy(options, [{ id: "remove" }], "id")

  if (!childID || _.size(childParentIDs) < 2)
    _.pullAllBy(options, [{ id: "removeParent" }], "id")

  // only deleted items can be restored
  if (deletedAt) {
    options.length = 0
    options.push({ id: "restore", name: "Restore" })
    options.push({ id: "delete", name: "Permanently" })
  }

  if (id === "deleted") {
    options.length = 0
    options.push({ id: "delete", name: "Permanently delete all" })
  }

  // ========================================= //
  // Functions
  // ========================================= //

  const handleLongPress = (event) => {
    setIsOpen(true)
  }

  const handleChange = (option) => {
    switch (option.id) {
      case "edit":
        return setMenuItem({ id, isOpen: true })
      case "move":
        return setShowMove("Move")
      case "add":
        return setShowMove("Add")
      case "remove":
        return actions.removeFromItem({ id, parentID })
      case "removeParent":
        return actions.removeFromItem({ id: childID, parentID: id })
      case "restore":
        return actions.deleteItem({ id, parentID, isDeleted: false })
      case "delete":
        if (id === "deleted") {
          const confirmed = window.confirm(
            "This cannot be undone. Are you sure?"
          )
          if (!confirmed) return false
          return actions.deleteAllItemsFinal()
        } else if (deletedAt) {
          const confirmed = window.confirm(
            "This will permanently delete this item. Are you sure?"
          )
          if (!confirmed) return false
          return actions.deleteOneItemFinal({ id, parentID })
        } else if (childCount) {
          const confirmed = window.confirm(
            "This will delete all nested items not also belonging to other items. Are you sure?"
          )
          if (!confirmed) return actions.deleteItem({ id, parentID })
        }
        actions.deleteItem({ id, parentID })
      default:
        return
    }
  }

  const close = () => {
    setIsOpen(false)
    setShowMove(false)
  }

  // ========================================= //
  // Render
  // ========================================= //

  return (
    <>
      <DropDownMenu
        useContextMenu={useContextMenu}
        className={className}
        options={options}
        activeIndex={false}
        onChange={handleChange}
        isOpen={isOpen}
        onDismiss={close}
      >
        <LongPress time={700} onLongPress={handleLongPress}>
          {/* <ArrowDropDownIcon /> */}
          {children ? children : <ArrowDropDownIcon />}
        </LongPress>
      </DropDownMenu>

      <AddParentModal
        id={id}
        isOpen={Boolean(showMove)}
        onClose={close}
        searchParams={{
          id,
          remove: showMove === "Move",
          parentID,
        }}
      />

      {/* {showMove &&
        ReactDOM.createPortal(
          <Modal
            title="Move item to:"
            open={true}
            onClose={() => setShowMove(false)}
          >
            <AddParentSearch id={id} onChange={() => setShowMove(false)} />
            Remove from current location
          </Modal>,
          document.body
        )} */}
    </>
  )
}

export default OptionsMenu
