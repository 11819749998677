import styled from "styled-components"

export const Container = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border: 1px black solid;
  display: flex;
  align-items: center;
  text-align: center;
  padding: 50px;
`