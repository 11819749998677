import PropTypes from "prop-types"
import styled from "styled-components"
import { useUser } from "store/user/selectors"

const Button = styled("a")`
  /* position: absolute;
  bottom: 30px;
  left: 30px; */
  position: absolute;
  top: 70%;
  left: 50%;
  display: block;
  transform: translate(-50%, -50%);
  color: #588cb1;
  text-transform: uppercase;
  border: 1px transparent solid;
  &:hover {
    border: 1px #588cb1 solid;
  }
`
// ========================================= //
//  Main component
// ========================================= //

const SigninGoogle = ({}) => {
  // ========================================= //
  //  State
  // ========================================= //

  const [, { signInWithGoogle }] = useUser()

  // ========================================= //
  // Functions
  // ========================================= //

  //   const handleClick = () => {
  //     alert("clicked")
  //     signInWithGoogle
  //   }

  // ========================================= //
  //  Render
  // ========================================= //

  return <Button onClick={signInWithGoogle}>google</Button>
}

// ========================================= //
// Define prop types and defaults
// ========================================= //

SigninGoogle.propTypes = {}

SigninGoogle.defaultProps = {}

// ========================================= //
// Default export
// ========================================= //

export default SigninGoogle
