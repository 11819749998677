import React, { useState, useEffect } from "react"
import styled, { css } from "styled-components"

// ========================================= //
// Styles
// ========================================= //

const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: ${props => (props.show ? 1000 : -1000)};
  min-height: 0vh;
  max-height: 0vh;
  overflow: hidden;
  width: 100%;
  background: ${props => props.background && props.background};
  transition: opacity 0.2s;
  opacity: 0;
  ${props => props.visible && visible};
`

const visible = css`
  min-height: 100vh;
  max-height: 100vh;
  opacity: 1;
`

// ========================================= //
// Main Component
// ========================================= //

const Overlay = ({ visible, children, background = "#ffffff52", onClose }) => {
  // ========================================= //
  // State
  // ========================================= //

  const [show, setShow] = useState(visible)

  // ========================================= //
  // Effects
  // ========================================= //

  useEffect(() => {
    let timer
    if (visible && !show) {
      setShow(true)
    } else if (!visible && show) {
      timer = setTimeout(() => setShow(false), 100)
    }
    return () => clearTimeout(timer)
  }, [visible, show])

  // ========================================= //
  // Functions
  // ========================================= //

  const handleClose = () => {
    if (onClose) onClose()
  }

  // ========================================= //
  // Render
  // ========================================= //
  
  return (
    <Container
      show={show ? 1 : 0}
      visible={visible ? 1 : 0}
      background={background}
      onClick={handleClose}
    >
      {children}
    </Container>
  )
}

export default Overlay
