import _ from "lodash"

const getItems = (
  db,
  usersCollection,
  userID,
  domainID,
  onUpdate,
  onRemove
) => {
  return new Promise((resolve, reject) => {
    let loaded = false
    // define object for storing unsub references
    db.unsubs = db.unsubs || {}
    // store items listener unsubscribe function
    db.unsubs.items = db
      .collection(usersCollection)
      .doc(userID)
      .collection("domains")
      .doc(domainID)
      .collection("items")
      .onSnapshot(snapshot => {
        const items = []
        snapshot.docChanges().forEach(change => {
          if (_.includes(["added", "modified"], change.type)) {
            const item = change.doc.data()
            // const { fromCache } = change.doc.metadata
            // console.log(item.name, fromCache ? "CACHE" : "!!!!!READ")

            if (loaded) {
              onUpdate(item)
            } else {
              items.push(item)
            }
          } else if (change.type === "removed") {
            if (loaded) onRemove(change.doc.data())
          } else {
            console.log("not one of those")
          }
        })
        if (!loaded) {
          // console.log("TOTAL:", _.size(items))
          // console.log("Completed:", _.size(_.filter(items, { completed: true })))
          // console.log("Deleted:", _.size(_.filter(items, { isDeleted: true })))

          resolve(items)
          loaded = true
        }
      })
  })
}
export default getItems
