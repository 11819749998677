import _ from "lodash"
import { call, select } from "redux-saga/effects"
// import { eventChannel } from "redux-saga"
import firebase from "../../firebase"
// import { env } from "../../../utils"
import differenceInDays from "date-fns/differenceInDays"
// import fromUnixTime from "date-fns/fromUnixTime"
// import { Date } from "window-or-global"

// permanently delete after 30 days
const DELETE_AFTER_DAYS = 30

function* deleteItems(action) {
  try {
    const deleteNow = action.type === "DELETE_ALL_ITEMS_FINAL"
    const deleteFN = shouldDelete.bind(false, deleteNow)
    const needDeleting = yield select(({ items }) => _.filter(items, deleteFN))

    if (!_.size(needDeleting)) return

    const [userID, domainID] = yield select((state) => [
      state.user.id,
      state.app.currentDomain.id,
    ])

    while (_.size(needDeleting)) {
      const item = needDeleting.pop()
      yield call(() => firebase.deleteItem(userID, domainID, item))
    }
  } catch (error) {
    console.log("Error in delete_items.js", error)
  }
}

export function* deleteItem(action) {
  try {
    const id = action.payload.id
    const [userID, domainID] = yield select((state) => [
      state.user.id,
      state.app.currentDomain.id,
    ])
    const item = yield select(({ items }) => items[id])
    yield call(() => firebase.deleteItem(userID, domainID, item))
  } catch (error) {
    console.log("Error in delete_items.js", error)
  }
}

const shouldDelete = (deleteNow, item) => {
  return (
    item.deletedAt &&
    (deleteNow || daysSinceDelete(item.deletedAt) >= DELETE_AFTER_DAYS)
  )
}

const daysSinceDelete = (deletedAt) => {
  return differenceInDays(Date.now(), deletedAt)
}

export default deleteItems
