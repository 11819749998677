import _ from "lodash"
// import updateItem from "./update_item"
import removeFromItem from "./remove_from_item"
import addToItem from "./add_to_item"
import SetStatusId from "./set_status_id"
// import { isSelfOrChild, arrange } from "../../../utils"

const moveItem = (state, action) => {
  const { id, oldParentID, newParentID, position, statusID } = action.payload

  state = addToItem(state, {
    payload: { id, parentID: newParentID, position },
  })

  // addTo Will fail and return old state if validation fails
  if (!_.includes(state[id].parentIDs, newParentID)) return state

  state = removeFromItem(state, {
    payload: { id, parentID: oldParentID },
  })

  if (statusID) {
    action.payload.parentID = newParentID
    state = SetStatusId(state, action)
  }

  return state
}

export default moveItem
