import { takeEvery } from "redux-saga/effects"
import fetchItems from "./fetch_items"
import deleteItems, { deleteItem } from "./delete_items"
// import manageActions, { runActions } from "./item_actions"
// import timer from "./timer"

function* itemsSagas() {
  // initially loaded items
  yield takeEvery("FETCH_ITEMS", fetchItems)
  yield takeEvery("SET_LOADING", deleteItems)
  yield takeEvery("DELETE_ALL_ITEMS_FINAL", deleteItems)
  yield takeEvery("DELETE_ONE_ITEM_FINAL", deleteItem)
  // initially loaded items
  // yield takeEvery("LOAD_ITEMS", manageActions)
  // updated item
  // yield takeEvery("LOAD_ITEM", manageActions)
  // start actions timer
  // yield takeEvery("FETCH_ITEMS", timer)
  // listen for timer events
  // yield takeEvery("MINUTE", runActions)
}

export default itemsSagas
