import _ from "lodash"
import React, { useRef, useEffect } from "react"
import styled, { css } from "styled-components"
import { useApp } from "../../../store/app/selectors"
import { useItem, useItemsPath } from "../../../store/items/selectors"
import Name from "../../elements/name"
import Box from "../../features/stats/box"
import FileCopyIcon from "@material-ui/icons/FileCopy"
import CreateIcon from "@material-ui/icons/Create"
import MuiButton from "@material-ui/core/Button"
import DateFnsUtils from "@date-io/date-fns"
import { subDays } from "date-fns"

window.dateFN = DateFnsUtils
window.subDays = subDays

// ========================================= //
// Style
// ========================================= //

const Container = styled.div`
  background: white;
  display: flex;
  flex: 1;
`

const Inner = styled.div`
  margin-top: 10px;
  padding: 15px;
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: center;
  justify-content: center;
`

const Stats = styled.div`
  display: flex;
`

const Button = styled(MuiButton)`
  margin-top: 20px;
`

// ========================================= //
// Main Component
// ========================================= //

const Journal = ({ id, className }) => {
  // ========================================= //
  // State
  // ========================================= //

  const [totalEntries, { createItem }] = useItem([id, "itemIDs"], _.size)
  const [lastEntryID] = useItem([id, "itemIDs"], _.first)
  const [lastEntry] = useItem(lastEntryID)
  // const [lastEntryPath] = useItemsPath(_.get(lastEntry, "id"))
  const navToLatest = useRef(null)
  const [, { displayIntention, setSetting }] = useApp(false)

  const [laurens] = useItem(false, ({ items }) =>
    _.filter(items, (item) => _.includes(JSON.stringify(item.description), "Lauren"))
  )
  const laurensCount = _.size(laurens)

  // ========================================= //
  // Effects
  // ========================================= //

  useEffect(() => {
    // nav to new entry upon creation
    if (navToLatest.current) {
      // displayIntention({ path: lastEntryPath })
      setSetting({ currentItemID: lastEntry.id })
    }
  }, [navToLatest.current])

  // ========================================= //
  // Functions
  // ========================================= //

  const addEntry = () => {
    const template = lastEntry
      ? _.cloneDeep(lastEntry)
      : {
          fields: {
            Written: {
              id: "Written",
              value: "",
              type: "date",
              name: "Written",
              index: 0,
            },
            Date: {
              id: "Date",
              name: "Date",
              value: "",
              type: "date",
              index: 1,
            },
          },
        }

    console.log(template)
    const fields = template.fields
    // reset field values
    // _.each(fields, (field) => delete field.value)

    // Set new date fields
    const now = new Date()
    fields.Written.value = fields.Written.value = now.toISOString()
    fields.Date.value = subDays(now, 1).toISOString()

    createItem({
      ...template,
      position: 0,
      parentIDs: [id],
      name: "New entry",
      description: false,
      fields,
    })
    navToLatest.current = true
  }

  // ========================================= //
  // Render˝
  // ========================================= //

  return (
    <Container>
      <Inner>
        {/* <Name id={id} tagName={"h3"} /> */}
        <Stats>
          <Box name="Entries" value={totalEntries} Icon={FileCopyIcon} />
          <Box
            name="Lauren mentions"
            value={laurensCount}
            Icon={FileCopyIcon}
          />
        </Stats>
        <Button variant="outlined" onClick={addEntry}>
          New Entry
        </Button>
      </Inner>
    </Container>
  )
}

export default Journal
