import _ from "lodash"
import React, { useEffect } from "react"
// import { window } from "window-or-global"
import { useApp } from "../../../store/app/selectors"
import { useItem } from "../../../store/items/selectors"

/**
 * Run misc functions and store actions from the browser
 * e.g. delete items, orphans, and misc data migrations not yet fully implemented 
 */


const Console = () => {
  // ========================================= //
  // State
  // ========================================= //

  const [, itemActions] = useItem(false)
  const [, appActions] = useApp(false)

  // ========================================= //
  // Effects
  // ========================================= //

  useEffect(() => {
    window.ntntnl = {
      fun: () => {
        console.log("current time is:", Date.now())
      },
      ...itemActions,
      ...appActions
    }
    console.log("Console interface ready...")
  }, [])

  // ========================================= //
  // Functions
  // ========================================= //

  // ========================================= //
  // Render
  // ========================================= //

  return null
}

export default Console
