import React, { useState, useEffect } from "react"
import styled from "styled-components"
import Modal from "../../features/modal"
import SignIn from "./signin"
import SignUp from "./signup"
import SigninGoogle from './signin_google'

// ========================================= //
// Style Components
// ========================================= //

const Container = styled.div`
  & > a {
    cursor: pointer;
    margin: 0 10px;
    padding: 20px;
    transition: all 0.3s;
    /* border: 1px white solid; */
    text-align: center;
    /* &:hover {
      color: #588cb1;
      border: 1px #588cb1 solid;
    } */
  }
`

const HaveInviteButton = styled("a")`
  position: absolute;
  top: 50%;
  left: 50%;
  display: block;
  transform: translate(-50%, -50%);
`

const SignInButton = styled("a")`
  /* position: absolute;
  bottom: 30px;
  left: 30px; */
  position: absolute;
  top: 50%;
  left: 50%;
  display: block;
  transform: translate(-50%, -50%);
  color: #588cb1;
  text-transform: uppercase;
  border: 1px transparent solid;
  &:hover {
    border: 1px #588cb1 solid;
  }
`

// ========================================= //
// Main Component
// ========================================= //

const Anonymous = ({ children }) => {
  // ========================================= //
  // State
  // ========================================= //

  const [form, setForm] = useState({ signUp: false, signIn: false })

  // ========================================= //
  // Functions
  // ========================================= //

  const openSignUp = (event) => {
    event.preventDefault()
    setForm({ signUp: true, signIn: false })
  }

  const openSignIn = (event) => {
    event.preventDefault()
    setForm({ signUp: false, signIn: true })
  }

  const onClose = () => {
    setForm({ signUp: false, signIn: false })
  }

  // ========================================= //
  // Render
  // ========================================= //

  return (
    <Container>
      <Modal open={form.signUp || form.signIn} onClose={onClose}>
        {form.signUp && <SignUp onClose={onClose} />}
        {form.signIn && <SignIn onClose={onClose} />}
      </Modal>
      {/* <HaveInviteButton onClick={openSignUp}>
        Have an invite code?
      </HaveInviteButton> */}
      <SignInButton onClick={openSignIn}>Sign In</SignInButton>
      <SigninGoogle />
    </Container>
  )
}

export default Anonymous
