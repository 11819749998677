import _ from "lodash"
import { useContext } from "react"
import { ThemeContext } from "styled-components"
import { useApp } from "store/app/selectors"
import Item from "components/features/item/index"
import OptionsBar from "./options_bar"
import { Container } from "components/_styles"
import List from "./list"
// import Parents from "./parents"
import { motion, AnimatePresence, AnimateSharedLayout } from "framer-motion"

const ItemList = ({ id, isMobile, width, className }) => {
  // ========================================= //
  // Render
  // ========================================= //

  const { device } = useContext(ThemeContext)
  const [activeIDs] = useApp(["activeIntentions"])
  const isHeader = _.last(activeIDs) === id
  const isActive = _.includes(activeIDs, id)
  const activeIndex = _.indexOf(activeIDs, id)
  const activeChildID = activeIndex > -1 ? activeIDs[activeIndex + 1] : false
  const [showParents] = useApp("show.parents")
  const showList = isHeader
  const showItem = isHeader || !activeChildID || (activeChildID && showParents)

  // ========================================= //
  // Render
  // ========================================= //

  return (
    <Container type='flexCol'>
      <AnimatePresence>
        {showItem && (
          <motion.div
            initial={{ opacity: 0, height: "auto" }}
            animate={{ opacity: 1, height: "auto" }}
            exit={{ opacity: 0, height: 0 }}
            style={{ flexGrow: 0 }}
          >
            <Item
              key={id + "-item"}
              id={id}
              isHeader={isHeader}
              isMobile={!device.isDesktopOrLaptop}
              width={width}
            />
          </motion.div>
        )}
      </AnimatePresence>

      <AnimatePresence>
        {isHeader && <OptionsBar id={id} width={width} />}
      </AnimatePresence>
      <AnimatePresence>
        {isActive && (
          <motion.div
            initial={{ opacity: 0, height: 0 }}
            animate={{ opacity: 1, height: "auto" }}
            exit={{ opacity: 0, height: 0 }}
          >
            <List id={id} width={width} activeChildID={activeChildID} />
          </motion.div>
        )}
      </AnimatePresence>
    </Container>
  )
}

export default ItemList
