// import _ from "lodash"
// import firebase from "../"
// import { setUser } from "../../user/actions"
// import { setLoading } from "../../app/actions"

const authenticationMiddleware = (store) => (next) => (action) => {
  if (action.type === "AUTHENTICATE_USER") {
    console.log("Auth middleware called but needs to be removed")
    // firebase
    //   .authenticate()
    //   .then(user => {
    //     // Custom Claim tokens for domain permissions. Don't need but...
    //     // firebase.getCustomClaimTokens().then(tokens =>  console.log('inside:', tokens))
    //     const { uid, email } = user
    //     store.dispatch(setUser({ authenticated: true, id: uid, email }))
    //   })
    //   .catch(err => {
    //     console.log(err)
    //     store.dispatch(setUser({ authenticated: false }))
    //     store.dispatch(setLoading({ loading: false }))
    //   })
  }

  return next(action)
}

export default authenticationMiddleware
