import _ from "lodash"
import React, {
  useMemo,
  useCallback,
  useRef,
  useEffect,
  useLayoutEffect,
  useState,
} from "react"
import { Node, Editor, Transforms, Range, createEditor } from "slate"
import {
  Slate,
  Editable,
  ReactEditor,
  withReact,
  useSelected,
  useFocused,
  useSlateStatic,
  useEditor,
} from "slate-react"
import PropTypes from "prop-types"
import Suggestions from "./suggestions"
import { Container } from "./style"

// ========================================= //
// Styles
// ========================================= //

// ========================================= //
//  Main component
// ========================================= //

const Element = ({ attributes, children, element, ...rest }) => {
  // ========================================= //
  // State
  // ========================================= //

  const editor = useEditor()
  const { selection } = editor
  const selected = useSelected()
  const focused = useFocused()
  const location = useRef(null)
  const value = _.trim(_.get(element, "children[0].text", ""))

  // ========================================= //
  // Effects
  // ========================================= //

  // Remove search if no longer selected
  useEffect(() => {
    if (selected) {
      location.current = _.cloneDeep(selection.anchor)
    } else {
      //cancel()
    }
  }, [selected])

  useEffect(() => {
    console.log("selected", selected, "focused:", focused)
  }, [selected, focused])

  // Listen for close/cancel search key events
  useEffect(() => {
    document.addEventListener("keydown", listener)
    return () => document.removeEventListener("keydown", listener)
  }, [value])

  // Don't save while searching: no point and slows UI
  useEffect(() => {
    editor.enableSave(false)
    return () => editor.enableSave(true)
  }, [])

  // ========================================= //
  // Functions
  // ========================================= //

  const listener = (event) => {
    if (!selected) return
    switch (event.key) {
      case "Backspace":
        if (!value.length) {
          event.preventDefault()
          cancel()
        }
        break
      case "Escape":
        event.preventDefault()
        cancel()
        break
      default:
    }
  }

  const cancel = () => {
    if (location.current) {
      // current path is to text child, but we need to remove the node
      location.current.path.pop()
      editor.extended.inlineSearch.remove(location.current.path)
    }
  }

  const select = (item) => {
    console.log("SELECT", item, location.current.path)
    editor.extended.inlineSearch.setLink(item.id, location.current)
  }

  // ========================================= //
  // Render
  // ========================================= //

  return (
    <Container
      {...attributes}
      selected={selected}
      empty={!value.length}
      // placeholder="Search items..."
    >
      {children}
      {value && (
        <Suggestions
          parentRef={attributes.ref}
          value={value}
          cancel={cancel}
          onSelect={select}
        />
      )}
    </Container>
  )
}

// ========================================= //
// Define prop types and defaults
// ========================================= //

Element.propTypes = {}

Element.defaultProps = {}

export default Element
