import _ from "lodash"
import React, { useEffect } from "react"
import PropTypes from "prop-types"
import { useSlate } from "slate-react"

// ========================================= //
//  Main component
// ========================================= //

const Element = ({ editor }) => {
  // ========================================= //
  // State
  // ========================================= //

  const { selection } = useSlate()

  const changed = !_.isEqual(selection, editor.extended.selection.current)
  // keep track of last selection in order to restore later when null (e.g. modals)
  if (changed && selection) {
    editor.extended.selection.last = editor.extended.selection.current
    editor.extended.selection.current = _.cloneDeep(selection)
  }

  // ========================================= //
  // Effects
  // ========================================= //

  useEffect(() => {
    if (changed) {
      _.map(editor.extended.selection.__callbacks, (fn) => fn(selection))
    }
  }, [selection, changed])

  // ========================================= //
  // Render
  // ========================================= //

  return null
}

// ========================================= //
// Define prop types and defaults
// ========================================= //

Element.propTypes = {}

Element.defaultProps = {}

export default Element
