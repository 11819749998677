import _ from "lodash"
import updateItem from "./update_item"
// import sessions from "./sessions"
// import updateParents from "./utils/update_parents"
// import removeFromItem from "./remove_from_item"

const deleteItemFinal = (state, action) => {
  const { id } = action.payload

  const item = _.cloneDeep(state[id])
  state = _.omit(state, id)

  _.map(item.parentIDs, (parentID) => {
    const parent = state[parentID]
    if (!parent) return
    _.remove(parent.itemIDs, (itemID) => itemID === id)
    state = updateItem(state, {
      payload: { fields: parent, overwrite: true },
    })
  })

  return state
}

export default deleteItemFinal
