import styled, { css } from "styled-components"
import SwipeableDrawer from "@material-ui/core/SwipeableDrawer"

// ========================================= //
// Styles
// ========================================= //

export const MuiDrawer = styled(SwipeableDrawer)`
  z-index: ${(props) => props.z} !important;
  .MuiDrawer-paper {
    overflow: hidden !important;
    z-index: ${(props) => props.z} !important;
    width: ${(props) => props.width || 250}px;
    ${(props) =>
      props.anchor === "right" &&
      css`
        border-left: 1px solid ${(props) => props.theme.section.border};
      `}
    ${(props) =>
      props.anchor === "left" &&
      css`
        border-right: 1px solid ${(props) => props.theme.section.border};
      `}
  }
  &.MuiDrawer-root.MuiDrawer-modal {
    /* the mui settings don't seem to remove this, so I am */
    ${(props) =>
      !props.overlay &&
      css`
        position: inherit !important;
      `}
  }
  .MuiBackdrop-root {
    background: #ffffffdb;
  }
`
