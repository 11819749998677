import _ from "lodash"
import React, { useState } from "react"
import PropTypes from "prop-types"
import { useItem } from "../../../../../../store/items/selectors"
import { Container, Pill } from "./style"
import { Popover } from "../../../../../elements"
import Editor from "../../../index"
import NavTo from "../../../../../elements/nav_to"
import { useSelected } from "slate-react"

// ========================================= //
//  Main component
// ========================================= //

const Element = ({ attributes, children, element, ...rest }) => {
  // ========================================= //
  // State
  // ========================================= //

  console.log("element", element)
  const { link } = element
  const { itemID } = link
  const [item] = useItem([itemID])
  const selected = useSelected()

  // ========================================= //
  // Effects
  // ========================================= //

  // ========================================= //
  // Functions
  // ========================================= //

  // ========================================= //
  // Render
  // ========================================= //

  if (!link.expand) console.log("Problem:", children)

  return (
    <Pill {...attributes} isSelected={selected} expand={true}>
      <Popover>
        <NavTo itemID={itemID} ctrl={false}>
          {link.expand
            ? item.name
            : _.get(children, "props.children.props.text.text", " ")}
          {children}
        </NavTo>
        <Popover.Content>
          {item.name}
          <Editor
            key={itemID + "editor"}
            id={itemID}
            listID="menu"
            editable={false}
          />
        </Popover.Content>
      </Popover>
    </Pill>
  )
}

// ========================================= //
// Define prop types and defaults
// ========================================= //

Element.propTypes = {}

Element.defaultProps = {}

export default Element
