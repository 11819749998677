import React from "react"
import { useApp } from "store/app/selectors"
import { Container, Menu, Nav, Search } from "./style"
import NavigationIcon from "@material-ui/icons/Navigation"
import SearchIcon from "@material-ui/icons/Search"
import MenuIcon from "@material-ui/icons/Menu"
import {
  motion,
  useMotionValue,
  useTransform,
  useElementScroll,
  useViewportScroll,
  AnimatePresence,
} from "framer-motion"
import useScrollDirection from "components/_hooks/use_scroll_direction"

// ========================================= //
// Main Component
// ========================================= //

const Top = ({ z, isMobile, className }) => {
  const [scrollingUp] = useApp("show.scrollDirection", (dir) => dir === "up")
  const show = !isMobile || scrollingUp

  // const direction = useScrollDirection({ initialDirection: "up" })
  // console.log("direction", direction)

  // const { scrollYProgress } = useViewportScroll()
  // const y = useTransform(scrollYProgress, value => value * 10)

  // const show = true //direction === "up"

  // console.log("scrollYProgress", y)

  const positionParams = isMobile
    ? {
        initial: { bottom: -100 },
        animate: { bottom: 0 },
        exit: { bottom: -100 },
      }
    : {}


    // console.log('SHOULD SHOW?', show);
  // ========================================= //
  // Render
  // ========================================= //

  return (
    <AnimatePresence>
      {show && (
        <Container z={z} {...positionParams}>
          <Menu name="Show/hide Menu" path="show.sidebar" Icon={MenuIcon} />
          <Nav
            name="Show/hide Navigation"
            Icon={NavigationIcon}
            path="show.navigator"
            size={isMobile ? "medium" : "small"}
            sepLeft={!isMobile}
          />
          <Search
            name="Show/hide Search"
            Icon={SearchIcon}
            path="show.search"
            toggle={true}
            size={isMobile ? "medium" : "small"}
            sepLeft={!isMobile}
            sepRight={!isMobile}
          />
        </Container>
      )}
    </AnimatePresence>
  )
}

export default Top
