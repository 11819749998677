import _ from "lodash"
// import root from "window-or-global"

function getEnvironment() {
  const domain = _.get(window, "location.host", "")

  switch (domain) {
    case "be.ntntnl.com":
      return "production"
    case "be.ta.ntntnl.com":
      return "staging"
    default:
      return "development"
  }
}

const environment = getEnvironment()

export const IS_DEVELOPMENT = process.env.NODE_ENV === "development"
export const IS_STAGING = environment === "staging"
export const IS_PRODUCTION = environment === "production"

const env = { environment, IS_DEVELOPMENT, IS_STAGING, IS_PRODUCTION }

export default env
