import Button from "./button"
import useAppState from "store/hooks/use_state"

const SetState = ({ Icon, path, toggle, value, className, ...props }) => {
  const [active, handleClick] = useAppState({ path, toggle, value })

  return (
    <Button
      Icon={Icon}
      isActive={active}
      className={[active ? "active" : "", className].join(" ")}
      onClick={handleClick}
      {...props}
    />
  )
}

// ========================================= //
// Export
// ========================================= //

export default SetState
