import _ from "lodash"
import React, { useState, useRef } from "react"
import styled, { css } from "styled-components"
import { useItem } from "../../../store/items/selectors"
import { useApp } from "../../../store/app/selectors"
import Name from "../../elements/name"
import Editor from "../../features/editor"
import ItemFields from "../../features/item_fields"
import ItemActions from "../../features/item_actions"
// import ItemOptionsMenu from "../../elements/item_options_menu"
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown"

import CompareArrowsIcon from "@material-ui/icons/CompareArrows"
import ZoomOutMapIcon from "@material-ui/icons/ZoomOutMap"
import FullscreenExitIcon from "@material-ui/icons/FullscreenExit"
import VerticalAlignBottomIcon from "@material-ui/icons/VerticalAlignBottom"
import VerticalAlignCenterIcon from "@material-ui/icons/VerticalAlignCenter"
import HeightIcon from "@material-ui/icons/Height"
import SetSetting from "../../elements/set_setting"
import Breadcrumbs from "../../features/breadcrumbs"
import BreadcrumbsTo from "../../features/breadcrumbs_to"
import FormatColorTextIcon from "@material-ui/icons/FormatColorText"
import { ViewArea } from "../../sections"
import { TopBar } from "../../features"
import useScrollDirection from "components/_hooks/use_scroll_direction"
import { Container } from "components/_styles"

// ========================================= //
// Style
// ========================================= //

const ContainerOLD = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 2px 20px;
  height: 100%;
  padding-top: 50px;
  padding-bottom: 70px;

  overflow: scroll;
  /* height: calc(100% - 5px); */
  /* border: 2px solid yellow; */
`
const Body = styled.div`
  display: flex;
  flex: 1;
  /* overflow: hidden; */
  /* border: 2px solid green; */
`

const NameHeader = styled(Name)`
  margin: 10px 10px 0px 0;
  /* width: 80%; */
  line-height: 25px;
`

const EditorStyled = styled(Editor)`
  line-height: 25px;
  padding-bottom: 30px;
`

// ========================================= //
// Main Component
// ========================================= //

const Main = ({ className }) => {
  // ========================================= //
  // State
  // ========================================= //

  const [id] = useApp("currentItemID")
  const ref = useRef(null)
  const direction = useScrollDirection({
    initialDirection: "up",
    ref,
    path: "show.scrollDirection",
  })

  // console.log('direction', direction);
  // ========================================= //
  // Effects
  // ========================================= //

  // ========================================= //
  // Functions
  // ========================================= //

  // ========================================= //
  // Render
  // ========================================= //

  return (
    <ViewArea mobileFullWidth={true}>
      {id && (
        <>
          <TopBar fixed={true}>
            <NameHeader
              key={id + "name"}
              id={id}
              listID="menu"
              tagName="h3"
              editable={true}
            />
          </TopBar>
          {/* <Body data-body-scroll-lock-ignore={true}> */}
          <Container
            className={className}
            ref={ref}
            scroll
            padding="50px 20px 70px 20px"
            style={{  height: "calc(100% - 130px)" }}
          >
            <EditorStyled
              key={id + "editor"}
              id={id}
              listID="menu"
              editable={true}
            />
            {/* </Body> */}
          </Container>
        </>
      )}
    </ViewArea>
  )
}

export default Main
