import { useContext } from "react"
import { ThemeContext } from "styled-components"
import Desktop from "./desktop"
import Mobile from "./mobile"

// ========================================= //
// Main Component
// ========================================= //

const Navigator = () => {
  // ========================================= //
  // State
  // ========================================= //

  const { device } = useContext(ThemeContext)

  // ========================================= //
  // Render
  // ========================================= //

  return device.isDesktopOrLaptop ? <Desktop /> : <Mobile />
}

export default Navigator
