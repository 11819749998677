import _ from "lodash"
import React, { useState, useRef, useEffect, useCallback } from "react"
import { useEditor } from "slate-react"
import PropTypes from "prop-types"
import { SuggestionContainer, Option } from "./style"
import Portal from "@material-ui/core/Portal"
import { useSearch } from "../../../../../../store/items/selectors"

// ========================================= //
//  Main component
// ========================================= //

const Suggestions = ({ parentRef, value, onSelect }) => {
  // ========================================= //
  // State
  // ========================================= //

  const editor = useEditor()
  const onAnyKeyOG = useRef(null)
  const elRef = useRef(null)
  const [index, setIndex] = useState(0)
  const [setValue, results] = useSearch({ limit: 5 })

  // ========================================= //
  // Effects
  // ========================================= //

  useEffect(() => {
    setValue(value)
  }, [value])

  useEffect(() => {
    const rect = parentRef.current.getBoundingClientRect()
    const el = elRef.current
    if (el) {
      el.style.top = `${rect.top + window.pageYOffset + 24}px`
      el.style.left = `${rect.left + window.pageXOffset}px`
    }
  }, [elRef.current])

  // ========================================= //
  // Suggestion navigation
  // ========================================= //

  useEffect(() => {
    document.addEventListener("keydown", listener)
    return () => document.removeEventListener("keydown", listener)
  }, [results, index])

  const listener = (event) => {
    switch (event.key) {
      case "ArrowDown":
        event.preventDefault()
        const last = results.length - 1
        setIndex((index) => (index >= last ? last : index + 1))
        break
      case "ArrowUp":
        event.preventDefault()
        setIndex((index) => (index <= 0 ? 0 : index - 1))
        break
      case "Tab":
      case "Enter":
        event.preventDefault()
        const item = results[index]
        if (item) onSelect(item)
        break
      default:
      // onKeyDown(event)
    }
  }

  const handleClick = (e, item) => {
    console.log("CLICKED")
    if (item) onSelect(item)
  }

  // ========================================= //
  // Render
  // ========================================= //

  return (
    <Portal container={document.body}>
      <SuggestionContainer ref={elRef}>
        {value && !_.size(results) && (
          <Option
            key="create"
            selected={true}
            onClick={(e) => handleClick(e, { id: "create" })}
          >
            Create item?
          </Option>
        )}
        {results.map((item, i) => (
          <Option
            key={item.id}
            selected={i === index}
            onClick={(e) => handleClick(e, item)}
          >
            {item.name}
          </Option>
        ))}
      </SuggestionContainer>
    </Portal>
  )
}

// ========================================= //
// Define prop types and defaults
// ========================================= //

Suggestions.propTypes = {}

Suggestions.defaultProps = {}

export default Suggestions
