import styled, { css } from "styled-components"

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* padding: 0 2px; */
  svg {
    margin: 0 2px;
    font-size: 20px;
    cursor: pointer;
    transition: all 0.3s;
    opacity: 0.5;
    &:hover {
      opacity: 1;
    }
  }
  border-bottom: 1px solid ${(props) => props.theme.section.border};
`


export const AddItemContainer = styled.div`
  border-left: 1px solid ${(props) => props.theme.section.border};
  display: flex;
  flex-direction: column;
  height: 100%;
`