import _ from "lodash"
import updateItem from "./update_item"
import addToItem from "./add_to_item"
// import conditions from "./conditions"

export function generateID() {
  return "_" + Math.random().toString(36).substr(2, 9)
}

export function newItem(fields, clean = true) {
  if (clean) {
    fields = _.omit(fields, [
      "id",
      "createdAt",
      "completed",
      "completedAt",
      "isArchived",
      "archivedAt",
      "isDeleted",
      "deletedAt",
      "satisfied",
      "satisfiedAt",
      "started",
      "startedAt",
      "paused",
      "pausedAt",
      "resumedAt",
      "sessions",
    ])
  }

  const parentID = fields.parentID
    ? fields.parentID
    : fields.parentIDs && fields.parentIDs[0]
    ? fields.parentIDs[0]
    : false

  const parentIDs = fields.parentIDs
    ? fields.parentIDs
    : parentID
    ? [parentID]
    : []

  const defaults = {
    id: generateID(),
    name: false,
    shortName: false,
    description: false,
    fields: {},
    parentID,
    parentIDs,
    itemIDs: [],
    linkedIDs: [],
    permissionIDs: [],
    statusID: "active",
    statusTempID: "default",
    createdAt: Date.now(),
    conditions: { satisfaction: {}, formation: {} },
  }

  // applied left to right. Once a property is set, additional values of the same property are ignored
  return _.defaultsDeep({}, fields, defaults)
}

const createItem = (state, action) => {
  const { fields, clean = true } = action.payload
  const position = fields.position
  delete fields.position
  const item = newItem(fields, clean)
  const { id, parentIDs } = item

  state = updateItem(state, { payload: { fields: item, overwrite: true } })

  // state = conditions.add(state, {
  //   payload: { id, type: "satisfaction", conditionID: "allCompleted" },
  // })

  _.map(parentIDs, (parentID) => {
    state = addToItem(state, { payload: { id, parentID, position } })
  })

  return state
}

export default createItem
