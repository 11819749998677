import _ from "lodash"
import { useApp } from "store/app/selectors"
import { motion, AnimatePresence, AnimateSharedLayout } from "framer-motion"
import { MotionStyled } from "./style"

// ========================================= //
// Main Component
// ========================================= //

const SlidingPanel = ({ children }) => {
  // ========================================= //
  // State
  // ========================================= //

  const [show, { setSetting }] = useApp("show.navigator")

  // ========================================= //
  // Functions
  // ========================================= //

  const handleDrag = () => {
    setSetting(_.set({}, "show.navigator", !show))
  }

  //   const handleDragDirection = (axis) => {
  //     console.log("here", axis)
  //   }

  // ========================================= //
  // Render
  // ========================================= //

  return (
    <AnimatePresence>
      {show && (
        <MotionStyled
          drag="y"
          layout
          onDragEnd={handleDrag}
        //   initial={{ top: 1000 }}
        //   animate={{ top: 50}}
        //   exit={{ top: 1000 }}
        initial={{ y: 1000 }}
        animate={{ y: 50}}
        exit={{ y: 1000 }}
          transition={{ duration: 0.5 }}

        >
          {children}
        </MotionStyled>
      )}
    </AnimatePresence>
  )
}

export default SlidingPanel
