import _ from "lodash"
import React, { useState } from "react"
import styled, { css } from "styled-components"
import PropTypes from "prop-types"
import { useItem } from "store/items/selectors"
import { Button } from "components/elements"
import FilterListIcon from "@material-ui/icons/FilterList"
import Menu from "@material-ui/core/Menu"
import MuiMenuItem from "@material-ui/core/MenuItem"
import ExpandLessIcon from "@material-ui/icons/ExpandLess"
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"

// ========================================= //
// Styles
// ========================================= //

const MenuItem = styled(MuiMenuItem)`
  display: flex;
  justify-content: space-between;
  ${(props) =>
    props.active &&
    css`
      background: red;
    `}

  ${(props) =>
    props.directionable &&
    css`
      padding-right: 0 !important;
      padding-top: 0;
      padding-bottom: 0;
    `}
`

const Direction = styled.div`
  margin-left: 5px;
`

// ========================================= //
//  Main component
// ========================================= //

const Filter = ({ id }) => {
  // ========================================= //
  // State
  // ========================================= //

  const [sortBy, { updateItem }] = useItem([id, "sortBy"], false, {
    type: "custom",
  })
  const [anchorEl, setAnchorEl] = useState(null)

  // ========================================= //
  // Effects
  // ========================================= //

  // ========================================= //
  // Functions
  // ========================================= //

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleSelect = (field, direction = "ASC") => {
    console.log("handleSElect", field, direction)
    updateItem({ id, sortBy: { ...sortBy, field, direction } })
    handleClose()
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  // ========================================= //
  // Render
  // ========================================= //

  return (
    <div>
      <Button
        name="Filter items"
        onClick={handleClick}
        Icon={FilterListIcon}
        size="small"
      />
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        variant="menu"
      >
        <Option
          label="Custom"
          field={false}
          value={sortBy.field}
          handleSelect={handleSelect}
          directionable={false}
        />
        <Option
          label="Name"
          field="name"
          value={sortBy.field}
          handleSelect={handleSelect}
        />
        <Option
          label="Created"
          field="createdAt"
          value={sortBy.field}
          handleSelect={handleSelect}
        />
        <Option
          label="Last updated"
          field="updatedAt"
          value={sortBy.field}
          handleSelect={handleSelect}
        />
      </Menu>
    </div>
  )
}

const Option = React.forwardRef(
  ({ label, field, value, directionable = true, handleSelect }, ref) => {
    const handleDirection = (event, direction) => {
      event.preventDefault()
      event.stopPropagation()
      handleSelect(field, direction)
    }

    return (
      <MenuItem
        ref={ref}
        selected={field === value}
        directionable={directionable ? 1 : 0}
        onClick={() => handleSelect(field)}
      >
        {label}
        {directionable && (
          <Direction>
            <Button
              name="Ascending"
              onClick={(e) => handleDirection(e, "ASC")}
              Icon={ExpandLessIcon}
              size="small"
            />

            <Button
              name="Descending"
              onClick={(e) => handleDirection(e, "DESC")}
              Icon={ExpandMoreIcon}
              size="small"
            />
          </Direction>
        )}
      </MenuItem>
    )
  }
)

// ========================================= //
// Define prop types and defaults
// ========================================= //

Filter.propTypes = {
  /**
   * The ID of the item to display sort settings for
   */
  id: PropTypes.string.isRequired,
}

Filter.defaultProps = {}

export default Filter
