import _ from "lodash"
import React, { useState, useEffect, useLayoutEffect, useRef } from "react"
import styled, { css, createGlobalStyle } from "styled-components"
import { useMediaQuery } from "react-responsive"
import { disableBodyScroll } from "body-scroll-lock"
import { ThemeProvider } from "styled-components"
import { setTheme } from "../../../style/theme"
import { useApp } from "../../../store/app/selectors"
import Notice from "./notice"
import MainNavigation from "../../features/main_navigation"
// import SetSetting from "../../elements/set_setting"
// import MenuIcon from "@material-ui/icons/Menu"
import OptionBar from "../../sections/option_bar"
import SideBar from "../../sections/side_bar"
import Navigator from "../../sections/navigator"
import Main from "../../sections/main"
import Panel2 from "../../sections/panel2"
import MainItemMenu from "../../sections/main_item_menu"
import KeyboardListeners from "./keyboard_listeners"
import Hotkeys from "./hotkeys"
import PlanningBoard from "../../sections/planning_board"
import ThemePicker from "../../features/theme_picker"
import NothingOpen from "components/sections/nothing_open"
import View from "../../sections/view"
import Search from "../../sections/search"
import Dinosaur from "../../sections/dinosaur"
import Console from "../common/console"
// import FirebaseAuth from './firebase_auth'

// ========================================= //
// Styled
// ========================================= //

const GlobalStyle = createGlobalStyle`
  HTML, body {
    background: ${(props) => props.theme.section.background};
  }
  * {
    /* Disable selection/Copy of UIWebView */
    /* https://gist.github.com/netpoetica/a1234ae1d4d2988f03ef */
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -webkit-tap-highlight-color: rgba(0,0,0,0);
  }
  /* #mouse-over-popover {
    display: inline-block;
    width: 100px;
  } */
`

const Container = styled.div`
  /* position: relative; */
  display: flex;
  width: 100vw;
  height: 100vh;
  /* overflow: auto; */

  /* border: 3px dashed teal; */
`

// const Container = styled.div`
//   position: relative;
//   display: grid;
//   /* grid-template-columns: 70px auto; */
//   grid-template-columns: 0px auto;
//   grid-template-rows: min-content auto;
//   grid-template-areas:
//     "navigation options"
//     "navigation boards";
//   width: 100vw;
//   height: 100vh;
// `

// const MenuIconStyled = styled(MenuIcon)`
//   position: absolute;
//   z-index: 10;
//   left: 15px;
//   color: ${(props) => props.theme.element.menu.text};
//   margin-right: 50px;
//   top: 12px;
//   cursor: pointer;
//   opacity: 0.6;
//   transition: all 0.3s;
//   &:hover {
//     opacity: 1;
//   }
// `

// const MainNavigationStyled = styled(MainNavigation)`
//   grid-area: navigation;
//   z-index: 100;
// `

// const OptionBarStyled = styled(OptionBar)`
//   grid-area: options;
//   z-index: 4;
// `

// const Sections = styled.div`
//   grid-area: boards;
//   display: flex;
//   overflow: hidden;
// `

// const visible = css`
//   max-width: 100%;
// `

// const panel = css`
//   display: flex;
//   transition: max-width 0.3s;
//   max-width: 0%;
//   overflow: auto;
//   ${(props) => props.visible && visible};
// `

// const PlanningBoardStyled = styled(PlanningBoard)`
//   /* ${panel}; */
//   max-width: ${(props) => (props.visible ? 300 : 0)}px;
//   min-width: ${(props) => (props.visible ? 300 : 0)}px;
//   ${(props) =>
//     props.visible &&
//     css`
//       border-right: 1px solid
//         ${(props) =>
//           props.theme.toHSL(props.theme.colors.primary, { s: -20, l: -10 })};
//     `}
//   overflow: auto;
//   z-index: 3;
// `

// const MainStyled = styled(Main)`
//   ${panel};
//   z-index: 1;
// `

// const Panel2Styled = styled(Panel2)`
//   ${panel};
//   border-left: ${(props) => (props.visible ? 1 : 0)}px solid
//     ${(props) =>
//       props.theme.toHSL(props.theme.colors.primary, { s: -15, l: -10 })};
//   z-index: 2;
// `

// const Wrapper = styled.div`
//   border: 3px blue solid;
//   display: flex;
//   flex-direction: column;
//   flex: 1;
//   height: 100vh;
// `

// const Inner = styled.div`
//   display: flex;
//   flex-direction: column;
//   overflow: auto;
// `

// ========================================= //
// Main Component
// ========================================= //

const Desktop = () => {
  // ========================================= //
  // State
  // ========================================= //

  const [userTheme] = useApp(["theme"])
  const isDesktopOrLaptop = useMediaQuery({
    query: "(min-device-width: 1224px)",
  })
  const isPortrait = useMediaQuery({ query: "(orientation: portrait)" })
  const isLandscape = useMediaQuery({ query: "(orientation: landscape)" })
  const isTabletOrMobileDevice = useMediaQuery({ maxDeviceWidth: 1224 })
  const theme = setTheme(userTheme)
  theme.device = {
    isDesktopOrLaptop,
    isPortrait,
    isLandscape,
    isTabletOrMobileDevice,
  }
  theme.device.isMobile = isTabletOrMobileDevice
  theme.device.isDesktop = !isTabletOrMobileDevice
  // const [ref] = useHookWithRefCallback()

  // // ========================================= //
  // // Effects
  // // ========================================= //

  // useLayoutEffect(() => {
  //   if (elRef) {
  //     disableBodyScroll(elRef.current)
  //     console.log('HERE it changed', elRef.current)
  //   } else {
  //     console.log('NOTHING', elRef);

  //   }
  // }, [elRef])

  // WHAT IS THIS FOR?
  // useEffect(() => {
  //   document.getElementsByTagName("html")[0].style.overflow = "hidden"
  //   disableBodyScroll(document.body, {
  //     allowTouchMove: (el) => {
  //       while (el && el !== document.body) {
  //         if (el.getAttribute("data-body-scroll-lock-ignore") !== null) {
  //           return true
  //         }
  //         el = el.parentNode
  //       }
  //     },
  //   })
  // }, [])

  // ========================================= //
  // Render
  // ========================================= //

  // return (
  //   <ThemeProvider theme={theme}>
  //     <Wrapper>
  //       <Inner>
  //         {_.times(100, (n) => (
  //           <div>hello {n}</div>
  //         ))}
  //       </Inner>
  //     </Wrapper>
  //   </ThemeProvider>
  // )

  return (
    <ThemeProvider theme={theme}>
      <GlobalStyle />
      <Hotkeys />
      <Console />
      <ThemePicker />
      <Notice />
      {/* <FirebaseAuth /> */}
      <Container>
        {/* <Dinosaur /> */}
        <Navigator />
        <SideBar />
        <NothingOpen />
        <View />
        <Search />
        <MainItemMenu />
      </Container>
    </ThemeProvider>
  )
}

export default Desktop
