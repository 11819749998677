import _ from "lodash"
import PropTypes from "prop-types"
import { useApp } from "store/app/selectors"

const useAppState = ({ path, value, toggle }) => {
  
  // ========================================= //
  // Defaults (no defaultProps hook support)
  // ========================================= //

  value = _.isUndefined(value) ? true : value
  toggle = _.isUndefined(toggle) ? true : toggle

  // ========================================= //
  // State
  // ========================================= //

  const [current, { setSetting }] = useApp(path)
  const active = current && _.isBoolean(current)

  // ========================================= //
  // Functions
  // ========================================= //

  const handleClick = (event) => {
    const next = toggle ? (active ? false : value) : value
    setSetting(_.set({}, path, next))
  }

  // ========================================= //
  // Return
  // ========================================= //

  return [active, handleClick]
}

// ========================================= //
// Define prop types and defaults
// ========================================= //

useAppState.propTypes = {
  // the app store path to change value of
  path: PropTypes.string.isRequired,
}

useAppState.defaultProps = {
  toggle: true,
  value: true,
}

export default useAppState
