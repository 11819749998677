import styled, { css } from "styled-components"
import { motion } from "framer-motion"

export const WIDTH = 300

export const MotionStyled = styled(motion.div)`
  position: absolute;
  z-index: 200;
  background: #fffffff7;
  border: 2px #ececec solid;
  border-radius: 15px;
  overflow: hidden;
  height: calc(100vh - 50px);
  width: calc(100vw - 4px);
  box-shadow: 0px 1px 15px -2px rgba(0, 0, 0, 0.16);
`
