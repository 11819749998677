import _ from "lodash"
import React, { useState } from "react"
import PropTypes from "prop-types"
import { useApp } from "store/app/selectors"
import { DisplayState } from "../../elements"
import { Margin } from "./styles"
import { AnimatePresence } from "framer-motion"

// ========================================= //
//  Main component
// ========================================= //

const TopBar = ({ children, center, fixed, className }) => {
  
  // ========================================= //
  // State
  // ========================================= //

  const [scrollingUp] = useApp("show.scrollDirection", (dir) => dir === "up")

  // ========================================= //
  // Render
  // ========================================= //

  return (
    <AnimatePresence>
      {scrollingUp && (
        <Margin
          className={className}
          center={center}
          fixed={fixed}
          initial={{ top: 0 }}
          animate={{ top: 0 }}
          exit={{ top: -100 }}
          transition={{ ease: "easeOut", duration: 1 }}
        >
          {children}
        </Margin>
      )}
    </AnimatePresence>
  )
}

// ========================================= //
// Define prop types and defaults
// ========================================= //

TopBar.propTypes = {}

TopBar.defaultProps = {
  fixed: false,
  center: true,
}

// ========================================= //
// Default export
// ========================================= //

export default TopBar
