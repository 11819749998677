import _ from "lodash"
import firebase from "../"
import { setCurrentDomain } from '../../app/actions'

const updateFirebase = store => next => action => {
  if (action.type === "SIGN_OUT") {
    return firebase.signOut(() => {
      next(action)
    })
  }

  // call reducers
  const results = next(action)

  // check if reducers returned changes
  const changes = _.get(results, "changes", {})

  if (action.changes) {
    const user = store.getState().user

    // domains changed
    if (_.size(changes.domains)) {
      // send each updated doc to firebase
      _.map(changes.domains, domain => {
        // delete domain.statusTemps.default 
        firebase.updateDomain(domain.ownerID || user.id, domain)
        if (action.type === "CREATE_DOMAIN") {
          // set new domain as active
          store.dispatch(setCurrentDomain(domain))
        }
      })

      if (action.type === "SHARE_DOMAIN") {
        const { email, domain } = action.payload
        firebase.shareDomain(user.id, domain, email)
      }
    }

    // items changed
    if (_.size(changes.items)) {
      const domain = store.getState().app.currentDomain
      // send each updated doc to firebase
      // console.log('changes', action.changes.items);
      _.map(action.changes.items, item => {    
        console.log('saving item', item.id);        
        firebase.updateItem(domain.ownerID || user.id, domain.id, item)
      })
    }
  }

  return results
}

export default updateFirebase
