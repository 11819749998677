import _ from "lodash"
import { useEffect } from "react"
import { useUser } from "store/user/selectors"

// ========================================= //
// Main Component
// ========================================= //

const Authentication = ({ children }) => {
  // ========================================= //
  // State
  // ========================================= //

  const [authenticated, { authenticateUser }] = useUser(["authenticated"])

  useEffect(() => {
    authenticateUser()
  }, [authenticateUser])

  // ========================================= //
  // Render
  // ========================================= //

  return !_.isNull(authenticated) && children
}

export default Authentication
