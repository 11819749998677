export function authenticateUser(payload) {
  return {
    type: "AUTHENTICATE_USER",
    payload,
  }
}

export function signInUser(payload) {
  console.log('signInUser called');
  
  return {
    type: "SIGN_IN_USER",
    payload,
  }
}

export function validateUser(payload) {  
  return {
    type: "VALIDATE_USER",
    payload,
  }
}

export function setUser(payload) {
  return {
    type: "SET_USER",
    payload,
  }
}

export function signOutUser(payload) {  
  return {
    type: "SIGN_OUT_USER",
    payload,
  }
}

export function signInWithGoogle(payload) {  
  return {
    type: "SIGN_IN_WITH_GOOGLE",
    payload,
  }
}

