import db, { auth, analytics, disconnect } from "./connection"
import authenticate from "./functions/authenticate"
import getCustomClaimTokens from "./functions/get_custom_claim_tokens"
import signIn from "./functions/sign_in"
import signOut from "./functions/sign_out"
import validateInvite from "./functions/validate_invite"
import createUser from "./functions/create_user"
import getDomain from "./functions/get_domain"
import getSharedDomain from "./functions/get_shared_domain"
import getDomains from "./functions/get_domains"
import updateDomain from "./functions/update_domain"
import shareDomain from "./functions/share_domain"
import getItems from "./functions/get_items"
import getItemsOnce from "./functions/get_items_once"
import updateItem from "./functions/update_item"
import deleteItem from "./functions/delete_item"
import loginWithGoogle from "./functions/login_with_google"
import { env } from "../../utils"

export const USERS_COLLECTION = env.IS_DEVELOPMENT ? "users-dev" : "users"
export const TOKEN_COLLECTION = env.IS_DEVELOPMENT
  ? "invite-codes-dev"
  : "invite-codes"

export const ROOT_URL = (() => {
  switch (env.environment) {
    case "development":
      return "http://localhost:8000/"
    case "staging":
      return "https://be.ta.ntntnl.com/"
    case "production":
      return "https://be.ntntnl.com/"
    default:
      return "https://be.ntntnl.com/"
  }
})()

const firebase = {
  auth,
  analytics,
  db,
  disconnect,
  authenticate: authenticate.bind(false, auth),
  getCustomClaimTokens: getCustomClaimTokens.bind(false, auth),
  signIn: signIn.bind(false, auth, ROOT_URL),
  signOut: signOut.bind(false, auth),
  validateInvite: validateInvite.bind(false, db, TOKEN_COLLECTION),
  createUser: createUser.bind(false, auth),
  getDomain: getDomain.bind(false, db, USERS_COLLECTION),
  getSharedDomain: getSharedDomain.bind(false, db, auth, USERS_COLLECTION),
  getDomains: getDomains.bind(false, db, USERS_COLLECTION),
  updateDomain: updateDomain.bind(false, db, USERS_COLLECTION),
  shareDomain: shareDomain.bind(false, db, USERS_COLLECTION),
  getItems: getItems.bind(false, db, USERS_COLLECTION),
  getItemsOnce: getItemsOnce.bind(false, db, USERS_COLLECTION),
  updateItem: updateItem.bind(false, db, USERS_COLLECTION),
  deleteItem: deleteItem.bind(false, db, USERS_COLLECTION),
  loginWithGoogle: loginWithGoogle.bind(false, auth)
}

export default firebase
