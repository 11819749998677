const deleteItem = (db, usersCollection, userID, domainID, item) => {
  // userID = userID ? userID : auth().currentUser.uid
  return db
    .collection(usersCollection)
    .doc(userID)
    .collection("domains")
    .doc(domainID)
    .collection("items")
    .doc(item.id)
    .delete()
    .then(() => {
      console.log("Document successfully deleted!")
    })
    .catch((error) => {
      console.error("Error removing document: ", error)
    })
}

export default deleteItem
