import _ from "lodash"
import PropTypes from "prop-types"
import { useContext } from "react"
import { ThemeContext } from "styled-components"

const DeviceVisibility = ({ children, ...props }) => {
  // ========================================= //
  // State
  // ========================================= //

  const { device } = useContext(ThemeContext)
  const devices = _.keys(_.pickBy(device))
  const visibleFor = _.keys(_.pickBy(props))
  const show = _.some(visibleFor, (d) => _.includes(devices, d))

  // ========================================= //
  // Render
  // ========================================= //

  return show ? children : null
}

// ========================================= //
// Define prop types and defaults
// ========================================= //

DeviceVisibility.propTypes = {
  /**
   * Show for desktop
   */
  isDesktop: PropTypes.bool,
  /**
   * Show for mobile
   */
  isMobile: PropTypes.bool,
}

DeviceVisibility.defaultProps = {}

export default DeviceVisibility
