import styled from "styled-components"

export const Container = styled.div`
  position: fixed;
  min-height: 100vh;
  width: 100%;
  transition: all 0.3s;
  opacity: ${(props) => props.opacity};
  background: white;
  z-index: 100;
`
