import { put } from "redux-saga/effects"
import firebase from "../../firebase"

function* validate(action) {
  try {
    console.log("validate saga called")

    if (firebase.auth().isSignInWithEmailLink(window.location.href)) {
      const email =
        window.localStorage.getItem("emailForSignIn") || prompt("email?")
      if (email) {
        // setValidating(true)
        firebase
          .auth()
          .signInWithEmailLink(email, window.location.href)
          .then(function (result) {
            // Success! Clear email from storage.
            window.localStorage.removeItem("emailForSignIn")
            // window.location.assign("/dashboard")
            window.history.replaceState({}, document.title, "/")
            // setValidating(false)
          })
          .catch(function (error) {
            console.log("login error:", error)
            // setValidating(false)
          })
      } else {
        console.log("NO EMAIL FOR VALIDATION SAGA")
      }
    } else {
      yield put({ type: "SET_USER", payload: { authenticated: false } })
      yield put({ type: "SET_LOADING", payload: false })
    }
  } catch (error) {}
}

export default validate
