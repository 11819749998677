import { call, put, take } from "redux-saga/effects"
import { eventChannel } from "redux-saga"
import firebase from "../../firebase"
import { env } from "../../../utils"

const fn =
  env.IS_DEVELOPMENT === "deletethis"
    ? // to cut down on query costs, use cache in development
      firebase.getItemsOnce.bind(false, "cache")
    : firebase.getItems

const getItemsChannel = (userID, domainID) => {
  return eventChannel((emit) => {
    const onUpdate = (item) => emit({ item })
    const onRemove = (item) => emit({ deleted: true, item })
    fn(userID, domainID, onUpdate, onRemove, true).then((items) =>
      emit({ items })
    )
    return firebase.db.unsubs.items
  })
}

function* fetchItems(action) {
  try {
    const { userID, domainID } = action.payload
    const channel = yield call(getItemsChannel, userID, domainID)

    while (true) {
      const { items, item, deleted } = yield take(channel)

      // dispatch initially loaded items
      if (items) yield put({ type: "LOAD_ITEMS", payload: { items } })

      // dispatch external updates
      if (item) yield put({ type: "LOAD_ITEM", payload: { item } })

      // remove item from redux
      if (deleted) yield put({ type: "DELETE_ITEM_FINAL", payload: item })
    }
  } catch (error) {
    console.log("FAILED", error)

    yield put({ type: "FAILED_FETCH_ITEMS", payload: { error } })
  }
}

export default fetchItems
