import Button from "./button"
import NavigateTo from "./navigate_to"
import SetState from "./set_state"

// ========================================= //
// Extend button
// ========================================= //

Button.NavigateTo = NavigateTo
Button.SetState = SetState

// ========================================= //
// Example props for View Builder
// ========================================= //

export const exampleProps = {
  name: "Click me",
  material: true,
}

// ========================================= //
// Export
// ========================================= //

export default Button
