import _ from "lodash"
import React from "react"
import { useApp } from "store/app/selectors"
import Top from "../top"
// WORKING VERSION
// import Column from "./column"
import ItemList from "../item_list"
import Drawer from "components/features/drawer"
import GoToItem from "../go_to_item"
import HomeIcon from "@material-ui/icons/Home"
import { motion, AnimatePresence, AnimateSharedLayout } from "framer-motion"
import { Container } from "components/_styles"
import { WIDTH } from "./style"
import SlidingPanel from "./sliding_panel"

// ========================================= //
// Main Component
// ========================================= //

const Navigator = ({ className }) => {
  // ========================================= //
  // State
  // ========================================= //

  // const [activeIDs] = useApp(["activeIntentions"])
  // const activeID = _.last(activeIDs)

  // ========================================= //
  // Render
  // ========================================= //

  return (
    <>
      <AnimateSharedLayout>
        <SlidingPanel>
          <Container>
            <ItemList key="baseintentions" id={"intentions"} width={window.innerWidth -2} />
          </Container>
        </SlidingPanel>
      </AnimateSharedLayout>

      <motion.div
        initial={{ opacity: 0, position: "absolute", bottom: -200 }}
        animate={{ opacity: 1, position: "absolute", bottom: 0 }}
        exit={{ opacity: 0, position: "absolute", bottom: -200 }}
      >
        <Top z={201} isMobile={true}/>
      </motion.div>
    </>
  )
}

export default Navigator
