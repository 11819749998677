import _ from "lodash"
import { useSelector } from "react-redux"
import { createSelector } from "reselect"

const selectorFn = (filterFn, path, state) => {
  const result = _.get(state, path)
  return filterFn ? filterFn(result, state) : result
}

const useStore = (type, path, filterFn) => {
  let selector
  if (path === false && !filterFn) {
    // undefined key will never return anything
    selector = (state) => state.nothingEver
  } else if (!path && filterFn) {
    selector = filterFn
  } else {
    path = _.isString(path) ? _.split(path, ".") : path
    path = _.compact(_.flatten([type, path]))
    selector = selectorFn.bind(false, filterFn, path)
  }

  // is createSelector even helping?
  const createdSelector = createSelector(selector, (val) => val)
  return useSelector(createdSelector, _.isEqual)
}

export default useStore
