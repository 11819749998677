import _ from "lodash"
import React, { useState } from "react"
import PropTypes from "prop-types"
import styled, { css } from "styled-components"
import { useItem } from "../../../store/items/selectors"
import NavTo from "../../elements/nav_to"
import Box from "../../features/stats/box"
import DeleteIcon from "@material-ui/icons/Delete"
import SubjectIcon from "@material-ui/icons/Subject"
import WcIcon from "@material-ui/icons/Wc"
// import { JSON } from "window-or-global"

// ========================================= //
// Styles
// ========================================= //

const Container = styled.div`
  padding: 30px;
  margin: 0 auto;
  display: flex;
  align-items: center;
`

// ========================================= //
//  Main component
// ========================================= //

const Dashboard = () => {
  // ========================================= //
  // State
  // ========================================= //

  const [total] = useItem(false, ({ items }) => _.size(items))
  const [deleted] = useItem(false, ({ items }) =>
    _.size(_.filter(items, "deletedAt"))
  )

  // const recursiveSearch = (obj) => {
  //   // if (_.isString(obj))

  //   const children = _.get(obj, 'children')
  //   if (children) {
  //     console.log('children found');
  //     return _.map(children, (node) => recursiveSearch(node))
  //   }

  //   const text = _.get(obj, 'text')
  //   return text && _.includes(text, "sex")
  // }

  // hacky but seems fast enough
  const hackSearch = (content) => {
    content = _.isObject(content) ? JSON.stringify(content) : content
    return _.includes(content, "sex")
  }

  const [sex] = useItem(false, ({ items }) =>
    _.filter(items, (item) => hackSearch(item.description))
  )

  // const [sex] = useItem(false, ({ items }) =>
  //   _.filter(items, (item) => _.includes(item.description, "sex"))
  // )
  const sexCount = _.size(sex)

  // ========================================= //
  // Effects
  // ========================================= //

  // ========================================= //
  // Functions
  // ========================================= //

  // ========================================= //
  // Render
  // ========================================= //

  console.log(sex[0])

  return (
    <Container>
      <Box name="Total Items" value={total} Icon={SubjectIcon} />
      <Box name="Deleted Items" value={deleted} Icon={DeleteIcon} />
      <Box name='Contains "sex"' value={sexCount} Icon={WcIcon} />

      {/* <div>
        {_.map(sex, (item, key) => (
          <div key={key}>
            {" "}
            <NavTo itemID={item.id}>{item.name}</NavTo>
          </div>
        ))}
      </div> */}
    </Container>
  )
}

// ========================================= //
// Define prop types and defaults
// ========================================= //

Dashboard.propTypes = {}

Dashboard.defaultProps = {}

export default Dashboard
