import _ from "lodash"
import PropTypes from "prop-types"
import DeviceVisibility from "components/elements/device_visibility"
import DisplayState from "components/elements/display_state"
import { Container } from "./style"
import MobileView from "./mobile"

// ========================================= //
//  Main component
// ========================================= //

const ViewArea = ({ center, mobileFullWidth, children }) => {
  // ========================================= //
  // Render
  // ========================================= //

  return (
    <>
      <DeviceVisibility isMobile>
        <MobileView>{children}</MobileView>
      </DeviceVisibility>
      <DeviceVisibility isDesktop>
        <DisplayState
          paths={["show.navigator", "show.item.info"]}
          center={center}
          mobileFullWidth={mobileFullWidth}
          Component={Container}
        >
          {children}
        </DisplayState>
      </DeviceVisibility>
    </>
  )
}

// ========================================= //
// Define prop types and defaults
// ========================================= //

ViewArea.propTypes = {
  /**
   * Should the view area stay centered even when sidebars are closed?
   */
  center: PropTypes.bool,
  /**
   * Should the view break from center when in mobile portrait?
   */
  mobileFullWidth: PropTypes.bool,
}

ViewArea.defaultProps = {
  center: true,
  mobileFullWidth: true,
}

export default ViewArea
