import _ from "lodash"
import { getChanges } from "./utils/track_changes"
import initState from "./init_state"
import loadItems from "./load_items"
import loadItem from "./load_item"
import createItem from "./create_item"
import updateItem from "./update_item"
import setFields from "./set_fields"
import addToItem from "./add_to_item"
import removeFromItem from "./remove_from_item"
import reorder from "./reorder"
import reorderFields from "./reorder_fields"
import moveItem from "./move_item"
import linkItem from "./link_item"
import updateItemParents from "./update_item_parents"
import sessions from "./sessions"
import actions from "./actions"
import conditions from "./conditions"
import completeItem from "./complete_item"
import setStatusID from "./set_status_id"
import satisfyItem from "./satisfy_item"
import archiveItem from "./archive_item"
import deleteItem from "./delete_item"
import deleteItemFinal from "./delete_item_final"

const getReducer = (type) => {
  switch (type) {
    case "LOADED":
      return initState
    case "LOAD_ITEMS":
      return loadItems
    case "CLEAR_ITEMS":
      return (state) => ({})
    case "LOAD_ITEM":
      return loadItem
    case "CREATE_ITEM":
      return createItem
    case "UPDATE_ITEM":
      return updateItem
    case "SET_FIELDS":
      return setFields
    case "ADD_TO_ITEM":
      return addToItem
    case "REMOVE_FROM_ITEM":
      return removeFromItem
    case "REORDER":
      return reorder
    case "REORDER_FIELDS":
      return reorderFields
    case "MOVE_ITEM":
      return moveItem
    case "LINK_ITEM":
      return linkItem
    case "UPDATE_ITEM_PARENTS":
      return updateItemParents
    case "SESSION_START":
      return sessions.start
    case "SESSION_PAUSE":
      return sessions.pause
    case "SESSION_STOP":
      return sessions.stop
    case "SESSION_REMOVE":
      return sessions.remove
    case "ADD_ACTION":
      return actions.add
    case "RUN_ACTION":
      return actions.run
    case "UPDATE_ACTION":
      return actions.update
    case "REMOVE_ACTION":
      return actions.remove
    case "ADD_CONDITION":
      return conditions.add
    case "RUN_CONDITION":
      return conditions.run
    case "REMOVE_CONDITION":
      return conditions.remove
    case "COMPLETE_ITEM":
      return completeItem
    case "SET_STATUS_ID":
      return setStatusID
    case "SATISFY_ITEM":
      return satisfyItem
    case "ARCHIVE_ITEM":
      return archiveItem
    case "DELETE_ITEM":
      return deleteItem
    case "DELETE_ITEM_FINAL":
      return deleteItemFinal
    default:
      return (state) => state
  }
}

const itemsReducer = (state = {}, action) => {
  state = _.cloneDeep(state)
  const reducer = getReducer(action.type)
  state = reducer(state, action)
  const publish = _.get(action, "payload.publish", true)
  if (publish) {
    _.set(action, ["changes", "items"], getChanges())
    // if (_.size(_.values(action.changes.items)))
    //   console.log("changes:", action.changes)
  }

  return state
}

export default itemsReducer
