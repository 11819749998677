import { createGlobalStyle } from "styled-components";

export const GlobalStyle = createGlobalStyle`
  HTML, body {
    height: 100vh;
    width: 100vw;
    padding: 0;
    margin: 0;
    font-family: 'Montserrat', sans-serif;
    color: #3e3e3e;
    /* overscroll-behavior-x: none; */
    /* overflow: hidden; */
    /* background: #f2f5f9; */
    overflow: 'auto';
    background-color: white;
  }
`;
