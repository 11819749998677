import _ from "lodash"
import React, { useState, useEffect, useRef } from "react"
import PropTypes from "prop-types"
import styled, { css } from "styled-components"
import { useApp } from "../../../store/app/selectors"
import OptionsMenu from "./options_menu"
import Base from "./base"
import ActiveMark from "./active_mark"
import { SetState } from "../../elements"
import ExpandArrow from "./expand_arrow"
import { Button } from "../../elements"
import { ExpandButton } from "./styles"
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight"
import Name from "../../elements/name"
import LazyLoad from "../../elements/lazy_load"
import { IconSelector } from "../../elements/icon"
import { motion, AnimatePresence } from "framer-motion"

// ========================================= //
// Style
// ========================================= //

const NameStyled = styled(Name)`
  grid-area: name;
  font-size: ${(props) => (props.theme.device.isDesktopOrLaptop ? 12 : 15)}px;
  margin: ${(props) => (props.theme.device.isDesktopOrLaptop ? 0 : "5px 0")};
  user-select: none;
`

// ========================================= //
// Main Component
// ========================================= //

/**
 *
 * For displaying items within lists
 */

const Item = ({
  id,
  index,
  parentID,
  childID,
  inTransition,
  width,
  isHeader,
  isMobile,
  onExpand,
  ...props
}) => {
  // ========================================= //
  // State
  // ========================================= //

  const [selectedID] = useApp("currentItemID")
  const [willExpand, setWillExpand] = useState(false)
  const exitAnimation = useRef(null)
  exitAnimation.current = {
    y: "auto",
    opacity: 0,
    transition: { duration: 0.1 },
  }

  // const [isHeaderActive] = useApp(
  //   ["activeIntentions"],
  //   (ids) => _.last(ids) === id
  // )

  const isOpen = selectedID === id

  // ========================================= //
  // Effects
  // ========================================= //

  // ========================================= //
  // Functions
  // ========================================= //

  const handleExpand = () => {
    if (onExpand) onExpand()
    // setWillExpand(true)
    console.log("Item clicked")
    // exitAnimation.current.y = 10
    // exitAnimation.current.y = -100
    // exitAnimation.current.opacity = 1
    // exitAnimation.current.zIndex = 100000
    // exitAnimation.current.position = 'absolute'
    // exitAnimation.current.transition = { duration: 3.5 }
  }

  // ========================================= //
  // Render
  // ========================================= //

  const variants = {
    visible: {
      height: "auto",
      opacity: 1,
      transition: {
        duration: 0.3,
        delay: (index || 1) * 0.3,
      },
    },
    hidden: { opacity: 0, height: isHeader ? 0 : "auto" },
  }

  // console.log("is header?", isHeader, willExpand)

  return (
    <OptionsMenu
      id={id}
      parentID={parentID}
      childID={childID}
      useContextMenu={true}
    >
      <SetState
        path={isMobile && !isOpen ? "show.navigator" : ""}
        value={true}
      >
        <SetState path={"currentItemID"} value={id}>
          <Base
            className={props.className}
            width={width}
            cardOptions={{}}
            {...{
              inTransition,
              // isHeader: selectedID === id,
              isHeader,
              isOpen,
            }}
          >
            {/* <IconSelector name="AcUnit" size={5} /> */}
            <NameStyled id={id} tagName="span" listID={"writer-list"} />
            {/* <ExpandArrow id={id} isOpen={isOpen} /> */}
            <ExpandButton
              itemID={id}
              name="Show/hide children"
              Icon={KeyboardArrowRightIcon}
              size="medium"
              onClick={handleExpand}
            />
            <ActiveMark isOpen={isOpen} />
          </Base>
        </SetState>
      </SetState>
    </OptionsMenu>
  )
}

export default Item

// ========================================= //
// Define prop types and defaults
// ========================================= //

Item.propTypes = {
  /**
   * The ID of the item to display
   */
  id: PropTypes.string.isRequired,
  /**
   * The ID of the item's current display parent (for template selection).
   *  */
  parentID: PropTypes.string,
  /**
   * I HAVE NO IDEA WHAT THIS IS FOR
   */
  childID: PropTypes.string,
  /**
   * Optional index/key when rendered within a list (enables lazy load)
   */
  index: PropTypes.number,
  /**
   * The width of the item (FIX: MAKE AUTO???)
   */
  width: PropTypes.number,
  /**
   * Is this item the current header item within the navigator?
   */
  isHeader: PropTypes.bool,
  /**
   * IS THIS WORKING ANYMORE?
   */
  inTransition: PropTypes.bool,
}

Item.defaultProps = {
  width: 300,
  isHeader: false,
  inTransition: false,
}
