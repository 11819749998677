import styled, { css } from "styled-components"
import { motion } from "framer-motion"

export const ButtonStyle = css`
  cursor: pointer;
  /* remove ios tap highlight */
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  opacity: 0.5;
  transition: all 0.3s;
  display: flex;
  align-items: center;
  &:hover {
    opacity: 1;
  }
  & svg {
    font-size: ${(props) => {
      switch (props.size) {
        case "xsmall":
          return props.theme.device.isTabletOrMobileDevice ? 16 : 12
        case "small":
          return props.theme.device.isTabletOrMobileDevice ? 20 : 15
        case "medium":
          return props.theme.device.isTabletOrMobileDevice ? 25 : 20
        case "large":
          return props.theme.device.isTabletOrMobileDevice ? 30 : 25
      }
    }}px !important;
    /* TODO: Should be moved to theme setting */
    padding: 10px 15px !important;
  }
  /* SetSetting adds the props.active */
  &.active {
    opacity: 1;
  }
  &:active {
    border: none;
  }
  border: none;
  background: none;
  outline: none;
`

export const InnerStyle = css`
  display: flex;
  align-items: center;
  /* background: red; */
`

export const Separator = styled.div`
  height: 20px;
  width: 1px;
  border-left: 1px solid ${(props) => props.theme.section.border};
`

export const StyledButton = styled.div`
  ${ButtonStyle}
`

export const MotionButton = styled(motion.div)`
  ${InnerStyle}
`