import _ from "lodash"
// import root from "window-or-global"
import React, { useState } from "react"
import styled from "styled-components"
import TextField from "@material-ui/core/TextField"
import Button from "@material-ui/core/Button"

const Content = styled.div`
  margin: 20px;
`

const StyledButton = styled(Button)`
  height: ${props => (props.visible ? "60px" : 0)};
  overflow: hidden;
  transition: all 0.3s;
  color: #607d8b;
  border-color: ${props => (props.visible ? "#607d8b" : "white")};
  margin-top: 20px;
`

const Input = styled(TextField)`
  margin: 10px 0 0 0;
  display: block;
`

const SignUp = ({ children }) => {
  const [code, setCode] = useState("")
  const [submitted, setSubmitted] = useState(false)

  const handleInput = ({ target: { value } }) => {
    setCode(_.trim(value))
  }

  const onFailed = msg => {
    alert(msg)
    setSubmitted(false)
  }

  const handleSubmit = event => {
    event.preventDefault()
    if (!code.length) return false
    setSubmitted(true)
    // firebase.validateInvite(
    //   code,
    //   data => {
    //     console.log(data)
    //     if (!data) return onFailed(`That wasn't a known invite token.`)
    //     const { claimed } = data
    //     if (claimed) return onFailed("That token was already claimed.")
    //     // create account
    //     const email = root.prompt("Great! Email?", "you@gmail.com")
    //     if (!email) return setSubmitted(false)
    //     const password = root.prompt("Create a password")
    //     if (!password) return setSubmitted(false)

    //     firebase.createUser(
    //       email,
    //       password,
    //       response => {
    //         // firebase auth will update causing authenticate.js to show dashboard
    //         console.log(response)
    //       },
    //       error => {
    //         onFailed(error.message)
    //         console.log(error)
    //       }
    //     )
    //   },
    //   error => {
    //     console.log(error)

    //     onFailed(
    //       "There was an error of some kind. Try again later. (I hate messages like this too, sorry.)"
    //     )
    //   }
    // )
  }

  const handleEnter = event => {
    if (event.key === "Enter") {
      event.preventDefault()
      handleSubmit(event)
    }
  }

  return (
    <Content>
      {submitted ? (
        <>Validating...</>
      ) : (
        <form noValidate autoComplete="off" onKeyDown={handleEnter}>
          <Input
            autoFocus
            placeholder="Account Code"
            value={code}
            onChange={handleInput}
          />
          <StyledButton
            visible={code.length}
            variant="outlined"
            color="primary"
            fullWidth
            onClick={handleSubmit}
          >
            Submit
          </StyledButton>
        </form>
      )}
    </Content>
  )
}

export default SignUp
