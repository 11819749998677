// import root from "window-or-global"
import conditions from "./reducers/conditions"
const storage = window.localStorage
const currentDomain = storage
  ? JSON.parse(storage.getItem("domain"))
  : {
      id: false,
    }
const theme = storage ? JSON.parse(storage.getItem("theme")) : false
const notice = storage ? storage.getItem("notice") : false

export const app = {
  notice,
  showCompact: true,
  multipleTabs: null,
  status: {
    itemsLoaded: false,
    domainsLoaded: false,
  },
  loading: true,
  loaded: false,
  currentDomain,
  planningBoard: { id: false, isOpen: false },
  showPlanner: "hidden",
  showSettings: false,
  menuItem: { id: false, parentID: false, isOpen: false },
  sections: {
    sidebar: false,
    menu: true,
    panel0: false,
    panel1: false,
    panel2: "writer",
  },
  views: {
    board: {
      columnType: "items",
      path: [],
    },
  },
  show: {
    navigator: true,
    search: false,
    item: {
      info: false
    },
    parents: true
  },
  statusBoardID: false,
  showMobileMenu: false,
  board: { id: "intentions", parentID: false },
  activeIntentionLimit: 3,
  activeIntentions: ["intentions"],
  currentItemID: false,
  bigTrackerItem: false,
  showActive: false,
  showQuickNav: false,
  showQuickAdd: false,
  newItemParentID: false,
  history: [],
  conditions,
  theme: theme || {
    colors: {
      primary: {
        h: 207.95031055900625,
        s: 0.29150000000000104,
        l: 0.8687,
        a: 0.86,
      },
      background: {
        h: 203.47826086956576,
        s: 0,
        l: 1,
        a: 0.88,
      },
      border: {
        h: 355.5279503105608,
        s: 0,
        l: 0.9151,
        a: 1,
      },
      accent: {
        h: 0,
        s: 0.6157246288451553,
        l: 0.6528531099999999,
        a: 1,
      },
    },
    //colors: { primary: { h: 214, s: 0.36, l: 0.9627, a: 0.98 } },
  },
}
