import PropTypes from "prop-types"
import { ThemeConsumer } from "styled-components"
import { Separator, StyledButton, MotionButton } from "./style"
import Tooltip from "@material-ui/core/Tooltip"
import MuiButton from "@material-ui/core/Button"

// ========================================= //
//  Main component
// ========================================= //

const Button = ({ Icon, name, sepLeft, sepRight, ...props }) => {
  const content = (
    <ThemeConsumer>
      {(theme) => (
        <>
          {sepLeft && <Separator />}
          <Tooltip
            title={name}
            enterDelay={1000}
            enterNextDelay={500}
            leaveDelay={200}
          >
            <MotionButton whileHover={{ scale: 1.1 }} whileTap={{ scale: 1.5 }}>
              {Icon ? (
                <Icon />
              ) : props.material ? (
                <MuiButton
                  variant="outlined"
                  style={{ height: "100%", width: "100%" }}
                >
                  {name}
                </MuiButton>
              ) : (
                <>{name}</>
              )}
            </MotionButton>
          </Tooltip>
          {sepRight && <Separator />}
        </>
      )}
    </ThemeConsumer>
  )

  return props.wrapped || props.material ? (
    <>{content}</>
  ) : (
    <StyledButton
      size={props.size}
      onClick={props.onClick}
      className={props.className}
      whileHover={{ scale: 1.1 }}
    >
      {content}
    </StyledButton>
  )
}

// ========================================= //
// Define prop types and defaults
// ========================================= //

Button.propTypes = {
  /**
   * The button's name for meta/hover info
   */
  name: PropTypes.string.isRequired,
  /**
   * A material UI icon
   */
  Icon: PropTypes.elementType,
  /**
   * Small, medium, or large
   */
  size: PropTypes.string,
  /**
   * The redux app store setting path
   */
  setting: PropTypes.string,
  /**
   * The value will be toggled true/false unless a string is provided, in which case value/false will be toggled.
   */
  value: PropTypes.any,
  /**
   * Should the setting value be toggled? Bool (true/false) String (string/false)
   */
  toggle: PropTypes.bool,
  /**
   * Optional click handler
   */
  onClick: PropTypes.func,
  /**
   * Use a material ui button?
   */
  material: PropTypes.bool,
}

Button.defaultProps = {
  size: "medium",
  toggle: true,
  material: false,
}

// ========================================= //
// Default export
// ========================================= //

export default Button
