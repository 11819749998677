import _ from "lodash"
import React, { useState } from "react"
import styled from "styled-components"
import NewItem from "components/features/new_item"
import { Button } from "components/elements"
import AddIcon from "@material-ui/icons/Add"

// ========================================= //
// Styles
// ========================================= //

const Add = styled.div`
  display: flex;
  flex-direction: column;
`

const NewItemStyled = styled(NewItem)`
  position: absolute;
  margin-top: 60px;
  margin-left: -10px;
  width: ${(props) => (props.width || 150) - 10}px;
`

// ========================================= //
// Main Component
// ========================================= //

const AddItem = ({
  listID,
  intentionID,
  fields,
  container,
  width,
  className,
}) => {
  // ========================================= //
  // State
  // ========================================= //

  const [showNewItem, setShowNewItem] = useState(false)

  // ========================================= //
  // Effects
  // ========================================= //

  // ========================================= //
  // Functions
  // ========================================= //

  // ========================================= //
  // Render
  // ========================================= //

  return (
    <>
      <Button
        name="Add item"
        onClick={() => setShowNewItem(true)}
        Icon={AddIcon}
        size="small"
      />

      <NewItemStyled
        key={listID}
        show={showNewItem}
        onBlur={() => setShowNewItem(false)}
        parentID={intentionID}
        fields={fields}
        width={width}
      />
    </>
  )
}

export default AddItem
