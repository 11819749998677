import styled from "styled-components"
import { Button } from "../../elements"

export const WIDTH = 250

export const TopSpacer = styled.div`
  width: 100%;
  height: 50px;
  min-height: 50px;
  border-bottom: 1px solid ${(props) => props.theme.section.border};
  display: flex;
  justify-content: flex-end;
  align-items: center;
  overflow: hidden;
`

export const Main = styled.div`
  /* display: flex;
  flex: 3 3 auto; */
  width: ${WIDTH}px;
  /* display: block; */
  /* height: 100%;
  overflow: hidden; */
  // height: 100%;
  // overflow: hidden;
  display: flex;
  height: 100%;
`

export const Home = styled(Button.NavigateTo)`
  opacity: 1;
  & svg {
    transition: all 0.1s;
    transition-delay: 0s;
    /* opacity: 0; */
  }
  &.active {
    opacity: 0.2;
    svg {
      transition: all 0.3s;
      transition-delay: 0.2s;
    }
  }
`
