import _ from "lodash"
import { Editor, Transforms, Text, Node, Range } from "slate"
import { ReactEditor } from "slate-react"
import Element from "./element"

// ========================================= //
//  Plugin
// ========================================= //

const withBase = (editor) => {
  // to be explicit about added editor functions
  _.set(editor, ["extended"], {})

  // define plugin objects to be extended by my plugins
  _.set(editor, ["plugins", "leaves"], {})
  _.set(editor, ["plugins", "elements"], {})
  _.set(editor, ["plugins", "components"], {})
  _.set(editor, ["plugins", "toolbar"], {})

  // set plugin render/support component (to run hooks)
  editor.plugins.components.base = Element

  // modified by with_base/element
  editor.extended.selection = { current: null, last: "nips" }

  // ========================================= //
  // Extend editor
  // ========================================= //

  editor.extended.selection.__callbacks = {}

  editor.extended.selection.onChange = (type, fn) => {
    editor.extended.selection.__callbacks[type] = fn
  }

  editor.extended.onKeyDown = (event) => {
    if (event.key === "ArrowRight") {
      editor.extended.onRightArrow(event)
    } else if (event.key === "ArrowLeft") {
      editor.extended.onLeftArrow(event)
    } else if (event.key === "ArrowDown") {
      editor.extended.onDownArrow(event)
    } else if (event.key === "ArrowUp") {
      editor.extended.onUpArrow(event)
    } else if (event.ctrlKey || event.metaKey) {
      editor.extended.onCommand(event)
    } else {
      editor.extended.onAnyKey(event)
    }
  }

  editor.extended.onAnyKey = (event) => {}

  editor.extended.onRightArrow = () => {
    // extended by my plugins
    // console.log("right arrow")
  }

  editor.extended.onLeftArrow = () => {
    // extended by my plugins
    // console.log("left arrow")
  }

  editor.extended.onUpArrow = () => {
    // extended by my plugins
    // console.log("up arrow")
  }

  editor.extended.onDownArrow = () => {
    // extended by my plugins
    // console.log("down arrow")
  }

  editor.extended.onCommand = (event) => {
    // extended by my plugins
  }

  editor.extended.setType = (type, path) => {
    Transforms.setNodes(editor, { type }, { at: path })
  }

  editor.extended.addTrailingSpace = (location) => {}

  editor.extended.getCurrentLeaf = () => {
    if (Range.isCollapsed(editor.selection)) {
      try {
        return Node.leaf(editor, editor.selection.focus.path)
      } catch {}
    }
  }

  editor.extended.getWordAtOffset = (str, pos) => {
    const { left, right } = __getWordRange(str, pos)

    // Return the word, using the located bounds to extract it from the string.
    return str.slice(left, right)
  }

  editor.extended.getCurrentWord = () => {
    const leaf = editor.extended.getCurrentLeaf()
    const offset = _.get(editor, "selection.focus.offset")
    if (!leaf || !offset) return false
    return editor.extended.getWordAtOffset(leaf.text, offset)
  }

  editor.extended.selectCurrentWord = () => {
    const leaf = editor.extended.getCurrentLeaf()
    const offset = _.get(editor, "selection.focus.offset")
    if (!leaf || !offset) return false
    const { left, right } = __getWordRange(leaf.text, offset)
    const path = _.cloneDeep(editor.selection.focus.path)
    const range = {
      anchor: { path },
      focus: { path },
    }

    range.anchor.offset = left
    range.focus.offset = right

    // console.log("word range:", range)
    Transforms.setSelection(editor, range)
    // console.log("opearations:", editor.operations)
    // const nextPoint = Editor.after(editor, editor.selection.anchor)
    // Transforms.setSelection(editor, { anchor: nextPoint, focus: nextPoint })
  }

  editor.extended.getCurrentLeafRange = () => {
    const leaf = editor.extended.getCurrentLeaf()
    if (!leaf) return
    const location = _.cloneDeep(editor.selection)
    // slate is returning the same object for anchor/focus
    location.anchor = _.cloneDeep(editor.selection.anchor)
    location.anchor.offset = 0
    location.focus.offset = _.size(leaf.text)

    return location
  }

  editor.extended.getSelectedText = () => {
    if (editor.selection) return Editor.string(editor, editor.selection)
  }

  editor.extended.getLocationText = (location) => {
    return Editor.string(editor, location)
  }

  editor.extended.selectNodeText = () => {
    const node = Node.get(editor, editor.selection.anchor.path)
    const range = _.cloneDeep(editor.selection)
    range.focus = _.cloneDeep(editor.selection.focus)
    range.anchor.offset = 0
    range.focus.offset = _.size(node.text)

    // select text
    Transforms.setSelection(editor, range)
  }

  editor.extended.noSelection = () => {
    return Range.isCollapsed(editor.selection)
  }

  editor.extended.restoreSelection = (selection) => {
    // Transforms.select(editor, selection || editor.extended.selection.last)
    // ReactEditor.focus(editor)
    // Transforms.setSelection(editor, selection || editor.extended.selection.last)
    Transforms.select(editor, selection || editor.extended.selection.last)
    console.log("restore:", editor.extended.selection.last)
    console.log("restored:", editor.selection)
  }

  // ========================================= //
  // Supporting functions
  // ========================================= //

  const __getWordRange = (str, pos) => {
    // Search for the word's beginning and end.
    // https://ourcodeworld.com/articles/read/223/how-to-retrieve-the-closest-word-in-a-string-with-a-given-index-in-javascript
    const left = str.slice(0, pos).search(/\S+$/)
    const offset = str.slice(pos).search(/\s/)
    // end of a line is a special case
    const right = offset < 0 ? pos : pos + offset
    return { left, right }
  }

  // ========================================= //
  // Return extended editor
  // ========================================= //

  return editor
}

export default withBase
