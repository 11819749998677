import React from "react"
import { Provider } from "react-redux"
import store from "store"
import { GlobalStyle } from "style/global"
import MultipleTabCheck from "components/sections/multiple_tab_check"
import LoadingOverlay from "components/features/loading_overlay"
import Authentication from "components/devices/common/authentication"
import DeviceContent from "components/devices/common/device_content"

// ========================================= //
// Main Component
// ========================================= //

const App = () => {
  // ========================================= //
  // Render
  // ========================================= //

  return (
    <Provider store={store}>
      <GlobalStyle />
      <MultipleTabCheck>
        <LoadingOverlay />
        <Authentication>
          <DeviceContent />
        </Authentication>
      </MultipleTabCheck>
    </Provider>
  )
}

export default App
