// import _ from "lodash"
import PropTypes from "prop-types"
import { Button } from "components/elements"
import { Container, AddItemContainer } from "./style"
import Filter from "./filter"
import AddItem from "./add_item"
import AccountTreeIcon from "@material-ui/icons/AccountTree"
import { motion } from "framer-motion"

// ========================================= //
//  Main component
// ========================================= //

const OptionsBar = ({ id, width }) => {
  // ========================================= //
  // State
  // ========================================= //

  // ========================================= //
  // Effects
  // ========================================= //

  // ========================================= //
  // Functions
  // ========================================= //

  // ========================================= //
  // Render
  // ========================================= //

  return (
    <motion.div
      layoutId="nav-options"
      key={`${id}-options`}
      initial={{ height: 0, overflow: "hidden" }}
      animate={{ height: "auto" }}
      exit={{ height: 0, overflow: "hidden" }}
    >
      <Container>
        {/* <Options /> */}
        <Button.SetState
          name="Show Parents"
          // onClick={() => alert("coming soon")}
          path="show.parents"
          Icon={AccountTreeIcon}
          size="small"
        />
        <Filter id={id} />
        {/* <AddItem parentID={id} /> */}
        <AddItem
          listID={id}
          intentionID={id}
          container={AddItemContainer}
          width={width}
        />
      </Container>
    </motion.div>
  )
}

// ========================================= //
// Define prop types and defaults
// ========================================= //

OptionsBar.propTypes = {
  /**
   * The ID of the item to display
   */
  id: PropTypes.string.isRequired,
}

OptionsBar.defaultProps = {}

export default OptionsBar
