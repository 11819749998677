import _ from "lodash"
import React from "react"
import { useApp } from "../../../store/app/selectors"
import Top from "./top"
// WORKING VERSION
// import Column from "./column"
import ItemList from "./item_list"
import Drawer from "../../features/drawer"
import GoToItem from "./go_to_item"
import HomeIcon from "@material-ui/icons/Home"
import { motion, AnimatePresence, AnimateSharedLayout } from "framer-motion"
import { Container } from "components/_styles"
import { WIDTH, TopSpacer, Home } from "./style"

// ========================================= //
// Main Component
// ========================================= //

const Navigator = ({ className }) => {
  // ========================================= //
  // State
  // ========================================= //

  const [activeIDs] = useApp(["activeIntentions"])
  const activeID = _.last(activeIDs)

  // ========================================= //
  // Render
  // ========================================= //

  return (
    <>
      <Top z={201} />
      <Container>
        <Drawer path="show.navigator" side="left" z={200} className={className}>
          <TopSpacer>
            <Home
              name="Go home"
              Icon={HomeIcon}
              itemID={"intentions"}
              size="small"
            />
            <GoToItem />
          </TopSpacer>
          <AnimateSharedLayout>
            <Container>
              <ItemList key="baseintentions" id={"intentions"} width={WIDTH} />
            </Container>
          </AnimateSharedLayout>
        </Drawer>
      </Container>
    </>
  )
}

export default Navigator
