import styled, { css } from "styled-components"
import { motion } from "framer-motion"

export const allowTouch = css`
  & * {
    /* Exception for input areas */
    /* https://gist.github.com/netpoetica/a1234ae1d4d2988f03ef */
    -webkit-touch-callout: default !important;
    -webkit-user-select: text !important;
  }
`

export const disableTouch = css`
  & * {
    /* Exception for input areas */
    /* https://gist.github.com/netpoetica/a1234ae1d4d2988f03ef */
    -webkit-touch-callout: default !important;
    -webkit-user-select: default !important;
  }
`

export const hideScroll = css`
  &::-webkit-scrollbar {
    display: none; /* Safari and Chrome */
  }
`

export const fullHeight = css`
  /* height: 100%; */
  /* min-height: 100%; */
  display: flex;
  flex: 1;
  height: 100%;
  /* flex-direction: column; */
  /* overflow: hidden; */
`

export const fixedHeight = css`
  height: ${(props) => props.height}px;
  border: 1px blue solid;
  overflow: hidden;
`

export const minHeight = css`
  min-height: ${(props) => props.minHeight}px;
`

export const maxHeight = css`
  max-height: ${(props) => props.maxHeight}px;
`

export const FullHeightContainer = styled.div`
  ${fullHeight}
`

export const activeParent = css`
  flex-direction: column;
  height: calc(100vh - ${(props) => props.offset}px);
  overflow: scroll;
  /* overflow: hidden; */
  /* height:  100vh; */
  /* height: 300px; */
`

export const activeList = css`
  border: 1px dashed black;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  overflow: scroll;

  /* overflow: hidden; */
`

export const scroll = css`
  ${fullHeight};
  ${hideScroll};
  overflow: scroll !important;
`

const types = {}

types.flexCol = css`
  display: flex;
  flex-direction: column;
`

types.flexColRev = css`
  display: flex;
  flex-direction: column-reverse;
`

const padding = css`
  padding: ${(props) => props.padding};
`

export const Container = styled.div`
  ${(props) => props.padding && padding}
  ${(props) => props.fullHeight && fullHeight}
  ${(props) => props.scroll && scroll}
  ${(props) => props.minHeight && minHeight}
  ${(props) => props.maxHeight && maxHeight}
  ${(props) => props.height && fixedHeight}
  ${(props) => props.activeList && activeList}
  ${(props) => props.activeParent && activeParent}
  ${(props) => props.type && types[props.type]}
  ${(props) =>
    props.mobileType &&
    !props.theme.device.isDesktopOrLaptop &&
    types[props.mobileType]}
`

export const MotionContainer = styled(motion.div)`
  ${(props) => props.fullHeight && fullHeight}
  ${(props) => props.scroll && scroll}
  ${(props) => props.minHeight && minHeight}
  ${(props) => props.maxHeight && maxHeight}
  ${(props) => props.height && fixedHeight}
  ${(props) => props.activeList && activeList}
  ${(props) => props.activeParent && activeParent}
  ${(props) => props.type && types[props.type]}
  ${(props) =>
    props.mobileType &&
    !props.theme.device.isDesktopOrLaptop &&
    types[props.mobileType]}
`
