import { call, put, take } from "redux-saga/effects"
import { eventChannel } from "redux-saga"
import firebase from "../../firebase"

let channel

const getAuthChannel = () => {
  return eventChannel((emit) => {
    const unsubscribe = firebase.auth().onAuthStateChanged((user) => {
      emit({ user })
    })
    return unsubscribe
  })
}

function* authenticate(action) {
  channel = yield call(getAuthChannel)
  try {
    while (true) {
      console.log("WHILE TRUE")
      const response = yield take(channel)
      const { user } = response

      if (user) {
        console.log("user found")
        window.history.replaceState({}, document.title, "/")
        yield put({
          type: "SET_USER",
          payload: { id: user.uid, email: user.email, authenticated: true },
        })
      } else {
        console.log("no user")
        yield put({ type: "VALIDATE_USER" })
      }
    }
  } catch (error) {
    console.log("error on authenticate", error)
  } finally {
    console.log("countdown terminated")
  }
}

export function* stopAuthentication(action) {
  if (channel) {
    console.log("closing old channel")
    channel.close()
    yield firebase.disconnect()
    // firebase.db.disableNetwork().then(function () {
    //   console.log("disabled firebase listeners")
    // })
  }
}

export default authenticate

// firebase
// .authenticate()
// .then(user => {
//   // Custom Claim tokens for domain permissions. Don't need but...
//   // firebase.getCustomClaimTokens().then(tokens =>  console.log('inside:', tokens))
//   const { uid, email } = user
//   store.dispatch(setUser({ authenticated: true, id: uid, email }))
// })
// .catch(err => {
//   console.log(err)
//   store.dispatch(setUser({ authenticated: false }))
//   store.dispatch(setLoading({ loading: false }))
// })
