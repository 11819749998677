import { combineReducers, applyMiddleware, createStore } from "redux"
import { composeWithDevTools } from "redux-devtools-extension"
import createSagaMiddleware from "redux-saga"
import { all } from "redux-saga/effects"
import singleTabCheck from "./firebase/middleware/single_tab_check"
import authentication from "./firebase/middleware/authentication"
import updateFirebase from "./firebase/middleware/update_firebase"
import analytics from "./firebase/middleware/analytics"
import updateModel from "./items/middleware"
import appReducer from "./app/reducers"
import appMiddleware from "./app/middleware"
import userReducer from "./user/reducers"
import userSagas from "./user/sagas"
import domainsReducer from "./domains/reducers"
import domainSagas from "./domains/sagas"
import itemsReducer from "./items/reducers"
import itemsSagas from "./items/sagas"
import tasks from "./tasks"
import logRocket from "./thirdparty/logrocket"
// import searchDB from './items/search'

// Combine all module reducers
const reducers = combineReducers({
  user: userReducer,
  app: appReducer,
  domains: domainsReducer,
  items: itemsReducer,
})

// THIS SHOULD PROBABLY BE MOVED TO MODULE SPECIFIC MIDDLEWARE
const rootReducer = (state, action) => {
  // clear all data on sign out
  if (action.type === "SIGN_OUT") {
    state = undefined
  }

  if (action.type === "SET_DOMAIN") {
    state.items = undefined
  }

  if (action.type === "LOADED") {
    // this will cause the top level intention to have the same name
    if (state.domains[state.app.domain.id]) {
      action.domainName = state.domains[state.app.domain.id].name
    }
  }

  return reducers(state, action)
}

// Create saga...the name says it
const sagaMiddleware = createSagaMiddleware()

// Combine all middleware with devtools
// const enhanced = composeWithDevTools({ trace: true })

const middleWare = composeWithDevTools(
  applyMiddleware(
    appMiddleware,
    singleTabCheck,
    logRocket,
    sagaMiddleware,
    authentication,
    updateFirebase,
    tasks,
    updateModel,
    analytics
  )
)

// Create the Redux store
const store = createStore(rootReducer, middleWare)

// Compose the root saga from module sagas
const rootSaga = function* rootSaga() {
  yield all([userSagas(), domainSagas(), itemsSagas()])
}

// Run the sagas
sagaMiddleware.run(rootSaga)

// Great job!
export default store
