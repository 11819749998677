import _ from "lodash"

const tasks = []
let taskScheduler
let mins = 0

const addTask = (task, interval = 1000) => {
  tasks.push(task)
}

const processTasks = (store) => {
  // const interval = ++mins * 1000
  console.log("tasks running:", mins)
  _.each(tasks, (task) => task(store))
}

const run = (store) => {
  clearTimeout(taskScheduler)
  processTasks(store)
  taskScheduler = setTimeout(() => run(store), 1000 * 60)
}

const scheduler = { addTask, tasks, run }

export default scheduler
