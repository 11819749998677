import _ from "lodash"
import React, { useEffect } from "react"
import styled, { css } from "styled-components"
import { useApp } from "../../../store/app/selectors"
import { Drawer } from "../../features"
import ItemOptionsMenu from "../../elements/item_options_menu"
import ViewSelect from "./view_select"
import ItemFields from "./item_fields"
import ItemActions from "../../features/item_actions"
import LinkedTerms from "./linked_terms"
import LinkedItems from "./linked_items"
import RawObject from "../../elements/raw_object"
import { Button } from "../../elements"
import MoreVertIcon from "@material-ui/icons/MoreVert"
import CloseIcon from "@material-ui/icons/Close"
import DeviceVisibility from "components/elements/device_visibility"

// ========================================= //
// Style
// ========================================= //

const Top = styled.div`
  width: 100%;
  height: 50px;
  min-height: 50px;
  display: flex;
  align-items: center;
  margin: 0 15px;
  padding-top: 2px;
  box-sizing: border-box;
`

const OpenClose = styled(Button.SetState)`
  position: absolute;
  top: 0px;
  right: 0px;
  z-index: 101;
  padding: 5px 0 5px 5px;
  /* background: white; */
  /* background: ${(props) =>
    props.theme.device.isMobile ? "none" : "white"}; */
`

const CloseView = styled(Button.SetState)`
  position: fixed;
  padding-top: 0px;
  top: 5px;
  right: 50px;
  /* Below the Details Drawer for nicer looking open/close */
  z-index: 99;
  transition: all 0.2s;
  ${(props) =>
    props.show &&
    css`
      /* -1 so that rightSep overlaps with Details left border */
      right: 249px;
      border-right: none;
    `}
`

const ItemOptionsMenuStyled = styled(ItemOptionsMenu)`
  cursor: pointer;
  transition: all 0.3;
  opacity: ${(props) => (props.show ? 1 : 0)};
  position: relative;
  z-index: ${(props) => (props.show ? 1 : -1)};
`

// ========================================= //
// Main Component
// ========================================= //

const Details = ({ className }) => {
  // ========================================= //
  // State
  // ========================================= //

  const [id, { setSetting }] = useApp("currentItemID")
  const [show] = useApp("show.item.info")

  // ========================================= //
  // Render
  // ========================================= //

  if (!id) return null

  return (
    <>
      <DeviceVisibility isDesktop>
        <CloseView
          name="Close (and save) Item"
          path="currentItemID"
          value={false}
          Icon={CloseIcon}
          size="medium"
          show={show ? 1 : 0}
          sepRight={true}
        />
      </DeviceVisibility>
      <OpenClose
        name="Show/hide details"
        path="show.item.info"
        Icon={MoreVertIcon}
        size="medium"
        show={show ? 1 : 0}
      />
      <Drawer path="show.item.info" side="right" z={100}>
        <Top>
          <ItemOptionsMenuStyled id={id} show={show} include={["delete"]} />
        </Top>
        {id && (
          <>
            <ViewSelect id={id} />
            <ItemFields id={id} />
            <LinkedTerms id={id} />
            <LinkedItems id={id} />
            <RawObject id={id} />
          </>
        )}
      </Drawer>
    </>
  )
}

export default Details
