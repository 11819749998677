import _ from "lodash"
import styled, { css } from "styled-components/macro"
import { motion } from "framer-motion"

const leftMargin = css`
  margin-left: 250px;
  width: calc(100vw - 250px);
`

const rightMargin = css`
  margin-right: 250px;
  width: calc(100vw - 250px);
`

const bothMargin = css`
  width: calc(100vw - 500px);
`

const mobile = css`
  /* background: red; */
  margin-top: 0px;
`

const desktop = css`
  ${bothMargin}
  margin: 0 auto;
`

export const Container = styled.div`
  ${(props) => (props.theme.device.isDesktopOrLaptop ? desktop : mobile)}
`

export const ContainerOLD = styled.div`
  ${({ show, center, mobileFullWidth }) => css`
    display: flex;
    flex: 1;
    transition: all 0.2s;
    width: 100vw;
    ${(_.get(show, "navigator") || center) && leftMargin}
    ${(_.get(show, "item.info") || center) && rightMargin}
  ${((_.get(show, "item.info") && _.get(show, "navigator")) || center) &&
    bothMargin}
  /* Portrait and Landscape */ 
  @media only screen 
  and (min-device-width: 768px) 
  and (max-device-width: 1024px) 
  and (orientation: portrait) 
  and (-webkit-min-device-pixel-ratio: 2) {
      ${mobileFullWidth &&
      center &&
      css`
        display: none;
        //width: calc(100vw - 60px);
        /* margin: 0%; */
        margin: 30px 30px;
        background: red;
      `}
    }
  `}
`

export const CloseItemConfirmation = styled(motion.div)`
  position: fixed;
  z-index: 0;
  top: 0;
  width: 100vw;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  /* background: red; */
  color: white;
  /* background: ${(props) => props.background || "green"}; */
`
