import _ from "lodash"
import { useState, useEffect } from "react"
import { useApp } from "store/app/selectors"
const SCROLL_UP = "up"
const SCROLL_DOWN = "down"

const useScrollDirection = ({
  initialDirection,
  thresholdPixels,
  off,
  ref,
  path,
} = {}) => {
  const [scrollDir, setScrollDir] = useState(initialDirection)
  const [appState, { setSetting }] = useApp(path)
  const el = _.get(ref, "current") || window

  useEffect(() => {
    console.log("direction changed", scrollDir)
    if (path && appState !== scrollDir) setSetting(_.set({}, path, scrollDir))
  }, [scrollDir])

  useEffect(() => {
    console.log("effect called", el)
    const threshold = thresholdPixels || 0
    let lastScrollY = _.get(el, "scrollTop", 0)
    let ticking = false

    const updateScrollDir = () => {
      const scrollY = el.scrollTop

      if (Math.abs(scrollY - lastScrollY) < threshold) {
        // We haven't exceeded the threshold
        ticking = false
        return
      }

      setScrollDir(scrollY > lastScrollY ? SCROLL_DOWN : SCROLL_UP)
      lastScrollY = scrollY > 0 ? scrollY : 0
      ticking = false
    }

    const onScroll = () => {
      if (!ticking) {
        window.requestAnimationFrame(updateScrollDir)
        ticking = true
      }
    }

    /**
     * Bind the scroll handler if `off` is set to false.
     * If `off` is set to true reset the scroll direction.
     */
    !off
      ? el.addEventListener("scroll", onScroll)
      : setScrollDir(initialDirection)
    return () => el.removeEventListener("scroll", onScroll)
  }, [initialDirection, thresholdPixels, off, el])

  return scrollDir
}

export default useScrollDirection
