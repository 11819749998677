import React, { useEffect } from "react"
import styled from "styled-components"
import { useItem } from "../../store/items/selectors"
import { useApp } from "../../store/app/selectors"
import { useDomains } from "../../store/domains/selectors"
import Builder from "./builder"
import Writer from "./writer"
import Kanban from "./kanban"
// import Nested from "./nested"
import Table from "./table"
import Journal from "./journal"
import Vocab from "./vocab"
import PhilPapers from "./phil_papers"
import Dashboard from "./dashboard"
import { SlideIn } from "../elements/effects"

// ========================================= //
// Load views
// ========================================= //

const ViewComponents = {
  builder: Builder,
  writer: Writer,
  kanban: Kanban,
  table: Table,
  journal: Journal,
  vocab: Vocab,
  philpapers: PhilPapers,
  dashboard: Dashboard,
}

// ========================================= //
// Style
// ========================================= //

const ContainerSlideIn = styled(SlideIn)`
  display: flex;
  flex: 1;
`

// ========================================= //
// Main Component
// ========================================= //

const View = ({ className }) => {
  // ========================================= //
  // State
  // ========================================= //

  const [id, { setSetting }] = useApp("currentItemID")
  const [isDeleted] = useItem([id, "isDeleted"])
  const [currentDomain] = useApp("currentDomain")
  const [views] = useDomains([currentDomain.id, "views"])
  const [itemViewID] = useItem([id, "view", "id"])
  const viewID = itemViewID || "writer"
  const view = views[itemViewID]
  const ViewComponent = ViewComponents[viewID]

  // // ========================================= //
  // // Effects
  // ========================================= //

  // close view if file is deleted
  useEffect(() => {
    if (isDeleted) setSetting({ currentItemID: false })
  }, [isDeleted, setSetting])

  // ========================================= //
  // Functions
  // ========================================= //

  // ========================================= //
  // Render
  // ========================================= //

  return (
    // <ContainerSlideIn className={className}>
      ViewComponent ? (
        <ViewComponent id={id} />
      ) : (
        <Builder id={id} view={view} />
      )
    // </ContainerSlideIn>
  )
}

export default View
