import _ from "lodash"
import updateItem from "./update_item"

const start = (state, action) => {
  const { id } = action.payload

  const item = {
    ...state[id],
    started: true,
    startedAt: Date.now(),
    resumedAt: Date.now(),
    paused: false,
    pausedAt: false,
    completed: false,
    completedAt: false,
    sessions: state[id].sessions || [],
  }

  return updateItem(state, { payload: { fields: item } })
}

const pause = (state, action, returnItem = false) => {
  const { id } = action.payload
  const item = state[id]

  const session = {
    from: item.resumedAt || item.startedAt || Date.now(),
    to: Date.now(),
  }
  const sessions = item.sessions || []
  sessions.push(session)

  _.merge(item, {
    paused: true,
    pausedAt: Date.now(),
    resumedAt: false,
    sessions,
  })

  return returnItem
    ? item
    : updateItem(state, { payload: { fields: item, overwrite: true } })
}

const stop = (state, action, returnItem = false) => {
  const { id } = action.payload
  const item = state[id]

  const changes =
    item.paused || !item.startedAt ? {} : pause(state, action, true)

  _.merge(item, changes, {
    started: false,
    startedAt: false,
    completed: false,
    completedAt: false,
  })

  return returnItem
    ? item
    : updateItem(state, { payload: { fields: item, overwrite: true } })
}

const remove = (state, action) => {
  const { id, session } = action.payload
  const item = state[id]
  _.remove(item.sessions, (s) => _.isEqual(s, session))

  return updateItem(state, { payload: { fields: item, overwrite: true } })
}

const sessions = { start, pause, stop, remove }
export default sessions
