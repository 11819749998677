import _ from "lodash"
import React, { useState, useCallback, useMemo, useRef, useEffect } from "react"
import { Slate, withReact } from "slate-react"
import { createEditor, Transforms, Editor, Text } from "slate"
import { withHistory } from "slate-history"
import { Container, EditableStyled } from "./style"
import useContent from "./use_content"
import withBase from "./plugins/with_base"
import withToolbar from "./plugins/with_toolbar"
import withFormats from "./plugins/with_formats"
import withMarkdown from "./plugins/with_markdown"
import withItems from "./plugins/with_items"
import withImages, { insertImage } from "./plugins/with_images"
import withAutoLink from "./plugins/with_items/with_links"
import Element from "./element"
import Leaf from "./leaf"
import Status from "./status"
import ScrollShadow from "../../elements/effects/scroll_shadow"
import MobileView from "./mobile"
import {
  disableBodyScroll,
  enableBodyScroll,
  clearAllBodyScrollLocks,
} from "body-scroll-lock"
import ScrollContainer from "./scroll_container"

// ========================================= //
// Main Component
// ========================================= //

const EditorContainer = ({ id, editable = true, className }) => {
  // ========================================= //
  // State
  // ========================================= //

  const [value, setValue, save, isDirty, enableSave] = useContent(id)
  const editor = useMemo(
    () =>
      // withAutoLink(
        withImages(
          withItems(
            withMarkdown(
              withFormats(
                withToolbar(withBase(withReact(withHistory(createEditor()))))
              )
            )
          )
        // )
      ),
    [withItems]
  )

  editor.enableSave = enableSave
  const editorSelection = useRef(editor.selection)

  // ========================================= //
  // Effects
  // ========================================= //

  // useEffect(() => {
  //   //console.log("count changed", _.get(_.last(editor.children), "type"))

  //   // the last node is a link which makes adding below it impossible
  //   if (_.get(_.last(editor.children), "type") !== "paragraph") {
  //     addBottomParagraph()
  //   }
  // }, [_.size(editor.children)])

  // ========================================= //
  // Functions
  // ========================================= //

  const handleInserts = (event) => {
    if (event.key === "l") {
      event.preventDefault()
      if (editor.extended.hasFormat("linkItem")) {
        // removeLink()
      } else {
        editor.insertThings("linkItem")
      }
    } else if (event.key === "m") {
      event.preventDefault()
      editor.insertThings("image")
    }
  }

  editor.insertThings = (type) => {
    if (type === "image") {
      // https://source.unsplash.com/kFrdX5IeQzI
      const url = window.prompt("Enter the URL of the image:")
      if (!url) return
      insertImage(editor, url)
    } else if (type === "item") {
      // setShowInsertModal(type)
    } else if (type === "linkItem") {
      // setShowInsertModal(type)
    }
  }

  const addBottomParagraph = () => {
    editorSelection.current = _.cloneDeep(editor.selection)
    editor.selection = false
    Transforms.insertNodes(editor, {
      type: "paragraph",
      children: [{ text: "" }],
    })
    Transforms.select(editor, editorSelection.current)
  }

  const addParagraph = (location) => {
    location = _.cloneDeep(location)
    Transforms.insertNodes(editor, {
      type: "paragraph",
      children: [{ text: "" }],
    })
  }

  const handleInsert = (itemID) => {
    const type = false //showInsertModal
    // setShowInsertModal(false)
    if (!itemID) return

    setTimeout(() => {
      if (type === "linkItem") {
        Transforms.setNodes(
          editor,
          { linkItem: true, itemID },
          // Apply it to text nodes, and split the text node up if the
          // selection is overlapping only part of it.
          { match: (n) => Text.isText(n), split: true }
        )
        return
      }

      // addParagraph(editor.selection)
      Transforms.setNodes(
        editor,
        {
          type: type === "item" ? "link-item" : "link-to-item",
          id: itemID,
          children: [],
        },
        { match: (n) => Editor.isBlock(editor, n) }
      )
    }, 50)
  }

  // ========================================= //
  // Render Types
  // ========================================= //

  const renderLeaf = useCallback(
    (props) => <Leaf plugins={editor.plugins.leaves} {...props} />,
    []
  )

  const renderElement = useCallback(
    (props) => <Element plugins={editor.plugins.elements} {...props} />,
    []
  )

  // ========================================= //
  // Render
  // ========================================= //

  return (
    // <ScrollContainer>
    <>
      <Status isDirty={isDirty} />
      <Slate editor={editor} value={value} onChange={setValue}>
        {_.map(_.get(editor, "plugins.components"), (Plugin, key) => (
          <Plugin key={key} editor={editor} />
        ))}
        <EditableStyled
          data-body-scroll-lock-ignore={true}
          className={["editor", className].join(" ")}
          placeholder="Description..."
          renderElement={renderElement}
          renderLeaf={renderLeaf}
          onKeyDown={editor.extended.onKeyDown}
          // spellCheck={true}
          // readOnly={true} // BUG not WORKING!!!!!
        />
      </Slate>
    </>
    //  </ScrollContainer>
  )
}

export default EditorContainer
