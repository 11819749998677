import _ from "lodash"
import React, { useState } from "react"
import styled, { css } from "styled-components"
import { useItem } from "../../store/items/selectors"
import { allowTouch } from "components/_styles"

// ========================================= //
// Styles
// ========================================= //

const Container = styled.div`
  margin-top: auto;
  display: flex;
  flex-direction: column;
  overflow: auto;
  & strong {
    opacity: 0.3;
    font-size: 13px;
    cursor: pointer;
  }
`

const Header = styled.div`
  cursor: pointer;
  font-size: 12px;
  font-weight: bold;
  padding: 10px 10px 10px 20px;
  border-top: 1px solid ${(props) => props.theme.section.border};
  border-bottom: 1px solid ${(props) => props.theme.section.border};
`

const Inner = styled.div`
  ${allowTouch}
  flex: 1 1 0;
  overflow: auto;
  transition: all 0.3s;
  padding: 0 20px;
  ${(props) =>
    props.expanded &&
    css`
      margin-top: 10px;
      flex: 1 1 800px;
    `}
`

// ========================================= //
// Main Component
// ========================================= //

const RawObject = ({ id }) => {
  // ========================================= //
  // State
  // ========================================= //

  const [item] = useItem([id])
  const [expanded, setExpanded] = useState(false)

  // ========================================= //
  // Effects
  // ========================================= //

  // ========================================= //
  // Functions
  // ========================================= //

  // ========================================= //
  // Render
  // ========================================= //

  return (
    <Container>
      <Header onClick={() => setExpanded(!expanded)}>Debug Info</Header>
      <Inner expanded={expanded}>
        <pre>{JSON.stringify(item, null, 2)}</pre>
      </Inner>
    </Container>
  )
}

export default RawObject
