import _ from "lodash"
import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import { ReactEditor, useEditor } from "slate-react"
import Modal from "./modal"

// ========================================= //
//  Main component
// ========================================= //

const LinkModal = ({ editor }) => {
  // ========================================= //
  // State
  // ========================================= //

  const [show, setShow] = useState(false)
  const [link, setLink] = useState(false)

  // this will be called from with_links/index.js
  editor.extended.link.showModal = () => setShow(true)

  // ========================================= //
  // Effects
  // ========================================= //

  useEffect(() => {
    if (!show && link) {
      setTimeout(() => {
        editor.extended.restoreSelection()
        editor.extended.link.create(link)
      }, 0)
    }
  }, [show, link])

  // ========================================= //
  // Functions
  // ========================================= //

  const handleSave = ({ itemID, expand }) => {
    setLink({ itemID, expand })
    handleClose()
    // handleClose(() => editor.extended.link.create({ itemID, expand }))
  }

  const handleRemove = (itemID) => {
    handleClose(editor.extended.link.remove)
  }

  const handleClose = (callback) => {
    // console.log('close', editor.extended.selection.current, editor.extended.selection.last);
    setShow(false)
    // avoid race condition: editor selection vs. modal transition
    // setTimeout(() => {
    //   // editor.extended.restoreSelection()
    //   // restore pre-modal focus
    //   // if (callback) callback()
    // }, 300)
  }

  // ========================================= //
  // Render
  // ========================================= //

  return (
    <>
      {show && (
        <Modal
          isOpen={show}
          link={editor.extended.link.get()}
          text={editor.extended.getSelectedText()}
          onSave={handleSave}
          onRemove={handleRemove}
          onClose={handleClose}
        />
      )}
    </>
  )
}

// ========================================= //
// Define prop types and defaults
// ========================================= //

LinkModal.propTypes = {
  /**
   * The ID of the item to display
   */
  // id: PropTypes.string.isRequired,
}

LinkModal.defaultProps = {}

export default LinkModal
