import styled, { css } from "styled-components"
import { motion } from "framer-motion"

export const Container = styled(motion.div)`
  position: absolute;
  z-index: 0;
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  filter: saturate(0.8);
`

export const Logo = styled.div`
  background-image: url(./logo512.png);

  height: 100px;
  width: 100px;

`
