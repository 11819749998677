import Button from "./button"
import NavTo from "../navigate_to"

const NavigateTo = ({ Icon, sepLeft, sepRight, ...props }) => {
  return (
    <NavTo {...props}>
      <Button
        Icon={Icon}
        //   wrapped={true}
        {...{ sepLeft, sepRight }}
        {...props}
      />
    </NavTo>
  )
}

// ========================================= //
// Export
// ========================================= //

export default NavigateTo
