import styled, { css } from "styled-components"
import { linkStyle } from "../../../../../elements/nav_to"

export const Container = styled.span`
  ${linkStyle}
`

export const Pill = styled.span`
  background: #f1f3f7;
  border-radius: 5px;
  padding: 0 5px;
  line-height: 30px;
  display: inline-block;
  ${({ isSelected, expand }) =>
    isSelected && expand &&
    css`
      border: 1px #adadad dashed;
      background: white;
    `}
`
