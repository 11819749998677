import styled from "styled-components"
import { Editable } from "slate-react"
import { allowTouch } from "../../_styles"

export const Container = styled.div`
  /* margin-top: -15px; */
  display: flex;
  flex: 1;
  /* overflow: none; */
  position: relative;
  height: 100%;
  /* overflow: scroll; */
  margin-top: 30px;
  padding-top: 30px;

  & * {
    /* What is this for? */
    -webkit-user-modify: read-write-plaintext-only;
  }
  ${allowTouch}
`

export const EditableStyled = styled(Editable)`
  ${allowTouch}
  color: #707783;
  font-size: 16px;
  line-height: 25px;
  /* display: flex;
  flex-direction: column;
  flex: 1; */
  /* display: flex; */
  /* height: 100%; */
  /* display: flex;
  flex: 1; */
  /* height: 100%; */
  height: calc(100% - 50px);
  width: 100%;
  /* overflow: scroll; */
  /* -webkit-overflow-scrolling: touch; */

  blockquote {
    border-left: 2px solid #ddd;
    margin-left: 0;
    margin-right: 0;
    padding-left: 10px;
    color: #aaa;
    font-style: italic;
  }

`

// Working perfectly on ipad
// const EditableStyled = styled(Editable)`
//   color: #707783;
//   font-size: 14px;
//   line-height: 20px;
//   border: 1px red solid;
//   /* height: 100%; */
//   overflow-y: auto;
//   -webkit-overflow-scrolling: touch;
//   /* width: 100%;
//   height: 100%; */
//   /* display: flex;
//   flex: 1; */
//   /* height: calc(100% + 1px); */
//   /* height: 100%; */

//   &::-webkit-scrollbar {
//     display: none; /* Safari and Chrome */
//   }
//   blockquote {
//     border-left: 2px solid #ddd;
//     margin-left: 0;
//     margin-right: 0;
//     padding-left: 10px;
//     color: #aaa;
//     font-style: italic;
//   }
//   /* What is this for? */
//   -webkit-user-modify: read-write-plaintext-only;
//   /* -webkit-user-select: none; */
// `
