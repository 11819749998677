import _ from "lodash"
// import PropTypes from "prop-types"
import { useApp } from "store/app/selectors"
import { CloseItemConfirmation } from "./style"
import { MotionContainer } from "components/_styles"

import { motion, useMotionValue, useTransform } from "framer-motion"

// ========================================= //
//  Main component
// ========================================= //

const MobileView = ({ children }) => {
  // ========================================= //
  // State
  // ========================================= //

  const [currentItemID, { setSetting }] = useApp("currentItemID")
  const y = useMotionValue(0)
  const background = useTransform(y, [0, 50], ["#fffff", "#1858ab"])
  const height = useTransform(y, [0, 50], [0, 50])

  // ========================================= //
  // Functions
  // ========================================= //

  const handleClose = (event, info) => {
    if (!currentItemID) return
    if (info.offset.y > 300) setSetting(_.set({}, "currentItemID", false))
  }

  // ========================================= //
  // Render
  // ========================================= //

  return (
    <>
      {currentItemID && (
        <CloseItemConfirmation style={{ background, height }}>
          Keep pulling to close Item
        </CloseItemConfirmation>
      )}

      <MotionContainer
        fullHeight={true}
        // data-body-scroll-lock-ignore={true}
        // style={{
        //   y,
        //   position: "relative",
        //   zIndex: 1,
        //   height: "100vh",
        //   width: "100vw",
        //   overflow: 'scroll'
        // }}
        // drag="y"
        // dragElastic={0.2}
        // dragConstraints={{
        //   top: 0,
        //   left: 0,
        //   right: 0,
        //   bottom: 0,
        // }}
        // onDrag={handleClose}
        // style={{y}}
      >
        {children}
      </MotionContainer>
    </>
  )
}

// ========================================= //
// Define prop types and defaults
// ========================================= //

MobileView.propTypes = {}

MobileView.defaultProps = {}

export default MobileView
