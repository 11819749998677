import _ from "lodash"
import { useCallback } from "react"
import useItem from './use_item'
import useRedux from "../../utils/use_redux"
import * as actions from "../actions"

const useItemChildren = (id, getFields = [], undefinedVal = []) => {
  const [sortBy] = useItem([id, 'sortBy'], false, { field: false })

  const sort = (itemIDs, { items }) => {
    const item = items[id]

    if (id !== "deleted") {
      itemIDs = _.filter(itemIDs, (id) => !items[id].deletedAt)
    }

    const { field, direction } = sortBy
    const direct = (ids) => (direction === "DESC" ? _.reverse(ids) : ids)

    return field ? direct(_.sortBy(itemIDs, (id) => item[field])) : itemIDs
  }

  return useRedux("items", actions, [id, "itemIDs"], sort, undefinedVal)
}

export default useItemChildren
