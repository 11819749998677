import React from "react"
import { useApp } from "store/app/selectors"
import Overlay from "components/elements/overlay"
import { Container } from "./style"

// ========================================= //
// Main Component
// ========================================= //

const MultipleTabs = ({ children }) => {
  // ========================================= //
  // State
  // ========================================= //

  const [multipleTabs] = useApp(["multipleTabs"])

  // ========================================= //
  // Render
  // ========================================= //

  return multipleTabs ? (
    <Overlay visible={true}>
      <Container>
        <div>Ntntnl is currently open in another tab.</div>
      </Container>
    </Overlay>
  ) : children ? (
    children
  ) : null
}

export default MultipleTabs
