import { useApp } from "store/app/selectors"
import { Container, Logo } from "./style"
import { AnimatePresence } from "framer-motion"

// ========================================= //
// Main Component
// ========================================= //

const NothingOpen = () => {
  const [currentItemID] = useApp("currentItemID")

  return (
    <AnimatePresence>
      {!currentItemID && (
        <Container
          initial={{ opacity: 0 }}
          animate={{
            opacity: [0, 0.5, 0.8, 0.8, 0.8],
            scale: [0, 0.5, 1, 1.3, 1],
            // rotate: [0, 0, 270, 270, 0],
            // borderRadius: ["20%", "20%", "50%", "50%", "20%"],
          }}
          exit={{ opacity: 0, transition: { duration: 0.2 } }}
          transition={{ duration: 1.5 }}
        >
          <img
            src={process.env.PUBLIC_URL + "/logo192.png"}
            alt="Logo"
            width={100}
          />
        </Container>
      )}
    </AnimatePresence>
  )
}

export default NothingOpen
