import React, { useState, useEffect } from "react"
import { useApp } from "store/app/selectors"
import Loading from "components/elements/loading"
import { Container } from "./style"

// ========================================= //
// Main Component
// ========================================= //

const LoadingOverlay = () => {
  // ========================================= //
  // State
  // ========================================= //

  const [loading] = useApp(["loading"])
  const [show, setShow] = useState(true)

  // ========================================= //
  // Effects
  // ========================================= //

  useEffect(() => {
    if (loading) return
    const timer = setTimeout(() => setShow(false), 310)
    return () => clearTimeout(timer)
  }, [loading])

  // ========================================= //
  // Render
  // ========================================= //

  return (
    show && (
      <Container opacity={loading ? 1 : 0}>
        <Loading />
      </Container>
    )
  )
}

export default LoadingOverlay
