import _ from "lodash"
import styled, { css } from "styled-components"
import { motion } from "framer-motion"

export const leftMarginClosed = css`
  margin-left: 150px;
`

export const rightMarginClosed = css`
  margin-right: 90px;
`

export const noMargin = css`
  margin-left: 0;
`

export const fixed = css`
  position: fixed;
  background: #fffffff0;
  /* position: sticky;
  top: 0;
  left: 0; */
  /* Be above navigation (201) when minimized */
  /* TODO: Moving the home nav button from top fixed div will also fix this */
  z-index: 10;
`

export const Margin = styled(motion.div)`
  /* background: red; */
  /* height: 50px; */
  min-height: 50px;
  /* To align just right with Navigator:top:buttons */
  padding-top: 1px;
  padding-left: 20px;
  display: flex;
  /* flex: 1; */
  width: 100%;
  /* align-items: center; */
  /* transition: all 0.2s; */
  margin-right: 30px;
  ${(props) => props.fixed && fixed};
  ${(props) => !_.get(props, "show.navigator") && leftMarginClosed};
  ${(props) => !_.get(props, "show.item.info") && rightMarginClosed};
  ${({ center }) => center && noMargin};
  ${(props) =>
    props.theme.device.isPortrait &&
    props.center &&
    css`
      /* Be below navigation (201) when it's opened on portrait tablet for centered items */
      z-index: 1;
      /* margin-left: 120px; */
    `};
`
