const loginWithGoogle = (auth) => {
  console.log("FUNCTION CALLED", auth)
  return new Promise((resolve, reject) => {
    console.log("INSIDE OF PROMISE")
    const provider = new auth.GoogleAuthProvider()
    try {
      auth().signInWithPopup(provider)
    } catch (e) {
      console.log("ERROR", e)
    }
    // .then((result) => {
    //   console.log('SUCCESS');

    //   // This gives you a Google Access Token. You can use it to access the Google API.
    //   var token = result.credential.accessToken
    //   // The signed-in user info.
    //   var user = result.user
    //   // ...
    //   console.log("logged in successfully", user)
    //   // resolve(user)
    // })
    // .catch((error) => {
    //   console.log('Failed');

    //   // Handle Errors here.
    //   var errorCode = error.code
    //   var errorMessage = error.message
    //   // The email of the user's account used.
    //   var email = error.email
    //   // The firebase.auth.AuthCredential type that was used.
    //   var credential = error.credential
    //   // ...
    //   console.log("login failed", errorMessage)
    //   // reject(errorMessage)
    // })
  })
}

export default loginWithGoogle
