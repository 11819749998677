import _ from "lodash"
import { useItemChildren } from "store/items/selectors"
import { MotionContainer } from "components/_styles"
import ItemList from "./index"
import { Container } from "components/_styles"
import { SizeMe } from "react-sizeme"
import { useRef, useEffect, useState } from "react"

const List = ({ id, width, activeChildID }) => {
  const [itemIDs] = useItemChildren(id)
  const children = activeChildID ? [activeChildID] : itemIDs
  const shouldScroll = !activeChildID
  const [offset, setOffset] = useState(0)
  const el = useRef(null)
  const topOffset = el.current ? el.current.getBoundingClientRect().top : 0
  // if (shouldScroll) console.log("offset", topOffset)

  // useEffect(() => {
  //   if (shouldScroll && el.current) {
  //     setTimeout(() => {
  //       setOffset(el.current.getBoundingClientRect().top)
  //     }, 1000)
  //   }
  // }, [el.current, shouldScroll, topOffset])

  return (
    // <SizeMe monitorHeight refreshRate={1000}>
    //   {({ size }) => (
        <Container ref={el} activeParent={shouldScroll} offset={topOffset}>
          {_.map(children, (itemID) => (
            <ItemList key={itemID + "-list"} id={itemID} width={width} />
          ))}
        </Container>
    //   )}
    // </SizeMe>
  )
}

export default List
