import styled, { css } from "styled-components"
import { Button } from "components/elements"
import { motion } from "framer-motion"

const mobile = css`
  position: fixed;
  bottom: 0px;
  left: 0;
  padding: 5px 0 20px 0px;
  justify-content: space-around;
  /* transform: scale(1.3); */
  border-top: 1px solid ${(props) => props.theme.section.border};
  width: 100%;

`
const desktop = css`
  position: fixed;
  top: 0px;
  left: 0;
`

export const Container = styled(motion.div)`
  padding: 5px 0 5px 0;
  /* To get on top of swipeable div for Navigator Drawer */
  z-index: ${({ z }) => z};
  display: flex;
  background: white;
  ${(props) => (props.theme.device.isDesktopOrLaptop ? desktop : mobile)}
`

export const Menu = styled(Button.SetState)`
  position: relative;
  /* Display on top of menu when open */
  z-index: 1100;
  svg {
    font-size: 20px;
  }
`

export const Nav = styled(Button.SetState)`
  & svg {
    transition: all 0.3s;
    transform: rotate(180deg);
  }
  ${(props) =>
    props.isActive &&
    css`
      & svg {
        color: green;
        transform: rotate(0deg);
      }
    `}
  &.active svg {
    transform: rotate(0deg);
  }
`
export const Search = styled(Button.SetState)``
