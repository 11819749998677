
const createUser = (auth, email, password = "123456", successFn, errorFn) => {
  if (!email) errorFn("No email provided")
  auth()
    .createUserWithEmailAndPassword(email, password)
    .then(snapshot => {
      if (successFn) successFn(snapshot)
    })
    .catch(function(error) {
      if (errorFn) errorFn(error)
      // Handle Errors here.
      // var errorCode = error.code
      // var errorMessage = error.message
      // ...
    })
}

export default createUser
