import { put } from "redux-saga/effects"
import firebase from "store/firebase"

function* signInGoogle(action) {
  try {
    firebase.loginWithGoogle()
    console.log('called and done?');
  } catch (error) {
    console.log('error', error);
  }
}

export default signInGoogle
