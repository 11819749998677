import _ from "lodash"
import useStore from "./use_store"
import useActions from "./use_actions"

const useRedux = (type, actions, path, filterFn, undefinedVal = false) => {
  const value = useStore(type, path, filterFn)
  const val = _.isUndefined(value) ? undefinedVal : value
  return [val, useActions(actions)]
}

export default useRedux
